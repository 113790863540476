import { gql } from "@apollo/client";

export const GET_QRS = gql`
  query product_by_id($id: ID!) {
    product_by_id(id: $id) {
      name
      website_url
      map {
        id
      }
      qrs(sort: ["date_created"]) {
        id
        name
      }
    }
  }
`;

export const CREATE_QR = gql`
  mutation create_qr_item($data: create_qr_input!) {
    create_qr_item(data: $data) {
      id
    }
  }
`;

export const UPDATE_QR = gql`
  mutation update_qr_item($id: ID!, $data: update_qr_input!) {
    update_qr_item(id: $id, data: $data) {
      id
    }
  }
`;

export const GET_QR_BY_NAME = gql`
  query product_by_id($id: ID!, $name: String!) {
    product_by_id(id: $id) {
      qrs(filter: { name: { _eq: $name } }) {
        id
        name
      }
    }
  }
`;
