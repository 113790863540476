import React from 'react';
import PropTypes from 'prop-types';

import closeIcon from '../../Assets/close.svg';
import './index.css';

const CustomInput = ({
  placeholder = '',
  value,
  onChange,
  onClickClear,
  inputRef,
  onFocus,
}) => {
  return (
    <div className='customInput'>
      <input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        ref={inputRef}
      />
      {Boolean(value.length) && (
        <div className='cancelSearchContainer'>
          <button className='cancelIcon cancelTooltip' onClick={onClickClear}>
            <img src={closeIcon} alt='closeIcon' />
            <span className='tooltiptext'>Borrar</span>
          </button>
        </div>
      )}
    </div>
  );
};

CustomInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickClear: PropTypes.func.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default CustomInput;
