import { gql } from "@apollo/client";

export const GET_BRAND = gql`
  query brand($cuit: String!) {
    brand(filter: { cuit: { _eq: $cuit } }) {
      name
      products {
        name
        id
      }
    }
  }
`;
