import { useEffect, useState } from "react";
import Verification from "./Verification";
import { useMutation } from "@apollo/client";
import { EDIT_BATCH, CREATE_BATCH } from "../../../graphql/trace/getQrBatches";
import CreateEvidence from "./CreateEvidence";
import BatchEvidence from "./BatchEvidence";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function EditFormItemGran(props) {
  const {
    id,
    title, //Va en el header
    subtitle, //Va en el header grisado
    icon, // iconito a la izquierda de la tarjeta.
    heading, //Heading del primer item de body
    location, //bajada del primer item del body, va grisado.
    outputIcon, //outputIconn del producto. H: , W:
    by, //Empresa que realiza el proceso.
    logoBy, // LogoBy de la Empresa que realiza el proceso. Opcional
    inputs, //Que se usó para llegar al producto final.
    evidence, // Array con los objetos de evidencia.
    batch,
    batchEvidences,
    refetch,
  } = props;

  const [batchNumber, setBatchNumber] = useState(batch?.batch_number);
  const [volume, setVolume] = useState(batch?.quantity);
  const [unit, setUnit] = useState(batch?.unit);
  const [loteSemilla, setLoteSemilla] = useState(batch?.lote_semilla);
  const [calibre, setCalibre] = useState(batch?.calibre);
  const [variedad, setVariedad] = useState(batch?.variedad);

  const [editBatch] = useMutation(EDIT_BATCH);
  const [createBatch] = useMutation(CREATE_BATCH);

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [evidenceToEdit, setEvidenceToEdit] = useState({});

  const handleClose = () => {
    setEdit(false);
    setEvidenceToEdit({});
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const { qrId } = useParams();

  const handleSubmitBatch = async () => {
    try {
      if (!!batch) {
        const data = {
          batch_number: batchNumber,
          quantity: +volume,
          unit: unit,
          lote_semilla: loteSemilla,
          calibre: calibre,
          variedad: variedad,
        };
        await editBatch({
          variables: {
            id: batch?.id,
            data: data,
          },
        });
        refetch();
        alert("El batch se editó correctamente");
      } else {
        const data = {
          batch_number: batchNumber,
          quantity: +volume,
          unit: unit,
          lote_semilla: loteSemilla,
          calibre: calibre,
          variedad: variedad,
          qr_id: {
            id: qrId,
          },
          stage_id: {
            id: id,
          },
        };
        await createBatch({
          variables: {
            data: data,
          },
        });
        refetch();
        alert("El batch se creó correctamente");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleEditEvidence = (evidence) => {
    setEvidenceToEdit(evidence);
    setShow(true);
    setEdit(true);
  };

  useEffect(() => {
    setBatchNumber(batch?.batch_number);
    setLoteSemilla(batch?.lote_semilla);
    setCalibre(batch?.calibre);
    setVariedad(batch?.variedad);
    setVolume(batch?.quantity);
    setUnit(batch?.unit);
  }, [batch]);

  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <div className="card  my-3">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between p-1">
                <div className="flex-fill">
                  <p>Definir Lote: {title}</p>
                  <div className="d-flex mb-3">
                    <div className="input-group me-3">
                      <span className="input-group-text" id="inputGroup-sizing-default">
                        Lote
                      </span>
                      <input value={batchNumber} className="form-control" name="batchNumber" onChange={(e) => setBatchNumber(e.target.value)} />
                    </div>
                    <div className="input-group ">
                      <span className="input-group-text" id="inputGroup-sizing-default">
                        Lote Semilla
                      </span>
                      <input value={loteSemilla} className="form-control" name="batchNumber" onChange={(e) => setLoteSemilla(e.target.value)} />
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="inputGroup-sizing-default">
                      Calibre
                    </span>
                    <input value={calibre} className="form-control" name="batchNumber" onChange={(e) => setCalibre(e.target.value)} />
                  </div>
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="inputGroup-sizing-default">
                      Variedad
                    </span>
                    <input value={variedad} className="form-control" name="batchNumber" onChange={(e) => setVariedad(e.target.value)} />
                  </div>
                  <div className="d-flex mb-3">
                    <div className="input-group me-3">
                      <span className="input-group-text" id="inputGroup-sizing-default">
                        Cantidad
                      </span>
                      <input value={volume} type="number" className="form-control" name="volume" onChange={(e) => setVolume(e.target.value)} />
                    </div>
                    <div className="input-group ">
                      <span className="input-group-text" id="inputGroup-sizing-default">
                        Unidad
                      </span>
                      <input value={unit} type="text" className="form-control" name="unit" onChange={(e) => setUnit(e.target.value)} />
                    </div>
                  </div>
                  {!!batch ? (
                    <>
                      {batchEvidences?.length ? <p>Evidencias: </p> : null}
                      {batchEvidences?.map(({ evidence_id }, index) => {
                        return (
                          <div key={index}>
                            <BatchEvidence
                              title={evidence_id.title}
                              subtitle={evidence_id.subtitle}
                              icon={evidence_id.icon}
                              // handleEditEvidence={() => handleEditEvidence(evidence_id)}
                            />
                          </div>
                        );
                      })}
                      <div className="mb-3">
                        <button className="btn btn-secondary" onClick={handleShow}>
                          <i className="bi bi-cloud-arrow-up-fill m-1" />
                          <span>Nueva Evidencia</span>
                        </button>
                      </div>
                    </>
                  ) : null}

                  <div>
                    <button onClick={handleSubmitBatch} className="btn btn-primary">
                      Guardar Cambios
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md">
          <div className="card border-light shadow my-3">
            <div className="card-header bg-transparent border-ligh">
              {title} <small className="text-muted">{batchNumber}</small>
              {loteSemilla ? (
                <p className="mb-0">
                  <small>Lote Semilla: {loteSemilla}</small>
                </p>
              ) : null}
              {calibre ? (
                <p className="mb-0">
                  <small>Calibre: {calibre}</small>
                </p>
              ) : null}
              {variedad ? (
                <p className="mb-0">
                  <small>Variedad: {variedad}</small>
                </p>
              ) : null}
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between p-1">
                {outputIcon && (
                  <img src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${outputIcon}`} className="m-2" alt="" width="60" height="60" />
                )}
                <div className="flex-fill">
                  <p className="card-title mb-0">{volume && unit ? `${volume} ${unit} de ${heading}` : heading}</p>
                  <p className="card-subtitle mb-2 text-muted">
                    <small>{location}</small>
                  </p>
                </div>
              </div>

              {inputs && (
                <div className="d-flex align-items-center justify-content-between p-1">
                  <div className="flex-fill">
                    <p className="card-title text-muted mb-0">
                      <small>Hecho con</small>
                    </p>
                    <p className="card-subtitle fw-light mb-2">{inputs}</p>
                  </div>
                </div>
              )}
              {by && (
                <div className="d-flex align-items-center justify-content-between p-1">
                  {logoBy && <img src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${logoBy}`} className="m-2" alt="" width="32" height="32" />}
                  <div className="flex-fill">
                    <p className="card-subtitle text-muted mb-0">
                      <small>Hecho por</small>
                    </p>
                    <p className="card-title mb-2">{by}</p>
                  </div>
                </div>
              )}
              {evidence && <Verification id={id} data={evidence} />}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <CreateEvidence show={show} handleClose={handleClose} edit={edit} evidenceToEdit={evidenceToEdit} batchId={batch?.id} refetch={refetch} />
      )}
    </>
  );
}

export default EditFormItemGran;
