import Proof from "./Proof";

function Verification(props) {
  const { id, data, visibility } = props;
  let validation = "show";
  if (visibility == "hide") {
    validation = "hide";
  }

  return (
    <div className="accordion accordion-flush" id={"accordion_" + id}>
      <div className="accordion-item">
        <div id="headingOne" className="mb-0 border-bottom-0">
          <button
            className="accordion-button bg-white pe-2 ps-0 pt-2 pb-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#collapse_" + id}
            aria-expanded="true"
            aria-controls={"collapse_" + id}
          >
            <p className="m-0">Verificaciones</p>
          </button>
        </div>
        <div
          id={"collapse_" + id}
          className={`accordion-collapse collapse ${validation}`}
          aria-labelledby="headingOne"
          data-bs-parent={"#accordion_" + id}
        >
          <ul className="list-group py-1">
            {data.map(({ evidence_id }, index) => {
              let className = "";
              if (index === 0) {
                className = "border-top-0";
              }
              if (index === Object.entries(data).length - 1) {
                className = "border-bottom-0";
              }
              return <Proof pid={id + "-" + index} data={evidence_id} className={className} key={index} />;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Verification;
