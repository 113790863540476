import crypto from "crypto";

export const generateFileHash = async (file) => {
  return new Promise((resolve, reject) => {
    const hash = crypto.createHash("sha256");
    const fileReader = new FileReader();

    fileReader.onload = () => {
      hash.update(fileReader.result);
      resolve(hash.digest());
    };

    fileReader.onerror = () => {
      reject(new Error("Error reading file."));
    };

    fileReader.readAsArrayBuffer(file);
  });
};