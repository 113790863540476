import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getReporstAction } from "../../actions/reportsActions";
import ReactTooltip from "react-tooltip";
import "./button-download.css";

const ButtonDownload = ({
  idHash,
  cropName,
  farm,
  producer,
  licenseCompanyTax,
  subLicenseCompanyTax,
  agreement2bsvs,
  existValueTon,
  tonRequestInput,
  agreementDDSS,
  validationCU,
}) => {
  const dispatch = useDispatch();
  const [existAgreement, setExistAgreement] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ISPROD === "true") {
      if (
        agreement2bsvs === null ||
        agreementDDSS === null ||
        validationCU === null
      ) {
        setExistAgreement(false);
      } else if (
        !(typeof agreement2bsvs === "string") ||
        !(typeof agreementDDSS === "string") ||
        !(typeof validationCU === "string")
      ) {
        setExistAgreement(true);
      } else if (existValueTon === "SPECIFIED") {
        setExistAgreement(true);
      } else if (tonRequestInput > 0) {
        setExistAgreement(true);
      } else {
        setExistAgreement(false);
      }
    } else {
      if (agreement2bsvs === null) {
        setExistAgreement(false);
      } else if (existValueTon === "SPECIFIED") {
        setExistAgreement(true);
      } else if (tonRequestInput > 0) {
        setExistAgreement(true);
      } else {
        setExistAgreement(false);
      }
    }
  }, [
    agreement2bsvs,
    existValueTon,
    tonRequestInput,
    agreementDDSS,
    validationCU,
  ]);

  const payload = {
    idHash,
    growerName: producer.grower_name,
    growerTaxId: producer.grower_cuit,
    cropName,
    farm,
    licenseCompanyTax,
    subLicenseCompanyTax,
  };

  const sendRequest = () => {
    dispatch(getReporstAction(payload));
  };

  return (
    <>
      {existAgreement && tonRequestInput > 0 ? (
        <button className="donwload-button px-0" onClick={sendRequest}>
          <div className="downloadReport d-flex align-items-center">
            <div className="chipIcon">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.739 15.71C11.8379 15.801 11.9545 15.8724 12.0822 15.92C12.3353 16.02 12.6192 16.02 12.8724 15.92C13 15.8724 13.1166 15.801 13.2155 15.71L16.3348 12.71C16.5306 12.5217 16.6406 12.2663 16.6406 12C16.6406 11.7337 16.5306 11.4783 16.3348 11.29C16.139 11.1017 15.8735 10.9959 15.5966 10.9959C15.3197 10.9959 15.0541 11.1017 14.8584 11.29L13.517 12.59V9C13.517 8.73478 13.4075 8.48043 13.2125 8.29289C13.0175 8.10535 12.753 8 12.4773 8C12.2015 8 11.937 8.10535 11.742 8.29289C11.547 8.48043 11.4375 8.73478 11.4375 9V12.59L10.0962 11.29C9.99953 11.1963 9.88453 11.1219 9.75783 11.0711C9.63112 11.0203 9.49522 10.9942 9.35795 10.9942C9.22069 10.9942 9.08479 11.0203 8.95808 11.0711C8.83137 11.1219 8.71637 11.1963 8.61971 11.29C8.52226 11.383 8.44491 11.4936 8.39212 11.6154C8.33933 11.7373 8.31215 11.868 8.31215 12C8.31215 12.132 8.33933 12.2627 8.39212 12.3846C8.44491 12.5064 8.52226 12.617 8.61971 12.71L11.739 15.71ZM12.4773 22C14.5337 22 16.544 21.4135 18.2539 20.3147C19.9638 19.2159 21.2965 17.6541 22.0835 15.8268C22.8705 13.9996 23.0764 11.9889 22.6752 10.0491C22.274 8.10928 21.2837 6.32745 19.8296 4.92893C18.3754 3.5304 16.5227 2.578 14.5058 2.19214C12.4888 1.80629 10.3982 2.00433 8.49823 2.7612C6.5983 3.51808 4.97439 4.7998 3.83188 6.44429C2.68936 8.08879 2.07954 10.0222 2.07954 12C2.07954 14.6522 3.17502 17.1957 5.12497 19.0711C6.09049 19.9996 7.23672 20.7362 8.49823 21.2388C9.75974 21.7413 11.1118 22 12.4773 22V22ZM12.4773 4C14.1225 4 15.7307 4.46919 17.0986 5.34824C18.4665 6.22729 19.5327 7.47672 20.1623 8.93853C20.7919 10.4003 20.9566 12.0089 20.6356 13.5607C20.3147 15.1126 19.5224 16.538 18.3591 17.6569C17.1958 18.7757 15.7136 19.5376 14.1001 19.8463C12.4865 20.155 10.814 19.9965 9.29404 19.391C7.77409 18.7855 6.47497 17.7602 5.56096 16.4446C4.64694 15.129 4.15909 13.5822 4.15909 12C4.15909 9.87827 5.03547 7.84343 6.59543 6.34314C8.15539 4.84285 10.2712 4 12.4773 4V4Z"
                  fill="#4CAF50"
                />
              </svg>
            </div>
            <span style={{ color: "#4CAF50", fontWeight: "bold" }}>
              Descargar Informe
            </span>
          </div>
        </button>
      ) : (
        <div className="downloadReport d-flex align-items-center">
          <div className="chipIcon">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.739 15.71C11.8379 15.801 11.9545 15.8724 12.0822 15.92C12.3353 16.02 12.6192 16.02 12.8724 15.92C13 15.8724 13.1166 15.801 13.2155 15.71L16.3348 12.71C16.5306 12.5217 16.6406 12.2663 16.6406 12C16.6406 11.7337 16.5306 11.4783 16.3348 11.29C16.139 11.1017 15.8735 10.9959 15.5966 10.9959C15.3197 10.9959 15.0541 11.1017 14.8584 11.29L13.517 12.59V9C13.517 8.73478 13.4075 8.48043 13.2125 8.29289C13.0175 8.10535 12.753 8 12.4773 8C12.2015 8 11.937 8.10535 11.742 8.29289C11.547 8.48043 11.4375 8.73478 11.4375 9V12.59L10.0962 11.29C9.99953 11.1963 9.88453 11.1219 9.75783 11.0711C9.63112 11.0203 9.49522 10.9942 9.35795 10.9942C9.22069 10.9942 9.08479 11.0203 8.95808 11.0711C8.83137 11.1219 8.71637 11.1963 8.61971 11.29C8.52226 11.383 8.44491 11.4936 8.39212 11.6154C8.33933 11.7373 8.31215 11.868 8.31215 12C8.31215 12.132 8.33933 12.2627 8.39212 12.3846C8.44491 12.5064 8.52226 12.617 8.61971 12.71L11.739 15.71ZM12.4773 22C14.5337 22 16.544 21.4135 18.2539 20.3147C19.9638 19.2159 21.2965 17.6541 22.0835 15.8268C22.8705 13.9996 23.0764 11.9889 22.6752 10.0491C22.274 8.10928 21.2837 6.32745 19.8296 4.92893C18.3754 3.5304 16.5227 2.578 14.5058 2.19214C12.4888 1.80629 10.3982 2.00433 8.49823 2.7612C6.5983 3.51808 4.97439 4.7998 3.83188 6.44429C2.68936 8.08879 2.07954 10.0222 2.07954 12C2.07954 14.6522 3.17502 17.1957 5.12497 19.0711C6.09049 19.9996 7.23672 20.7362 8.49823 21.2388C9.75974 21.7413 11.1118 22 12.4773 22V22ZM12.4773 4C14.1225 4 15.7307 4.46919 17.0986 5.34824C18.4665 6.22729 19.5327 7.47672 20.1623 8.93853C20.7919 10.4003 20.9566 12.0089 20.6356 13.5607C20.3147 15.1126 19.5224 16.538 18.3591 17.6569C17.1958 18.7757 15.7136 19.5376 14.1001 19.8463C12.4865 20.155 10.814 19.9965 9.29404 19.391C7.77409 18.7855 6.47497 17.7602 5.56096 16.4446C4.64694 15.129 4.15909 13.5822 4.15909 12C4.15909 9.87827 5.03547 7.84343 6.59543 6.34314C8.15539 4.84285 10.2712 4 12.4773 4V4Z"
                fill="#C2C2C2"
              />
            </svg>
          </div>
          <span data-tip data-for="downloadInfo">
            Descargar Informe
          </span>
        </div>
      )}
      <ReactTooltip id="downloadInfo" className="custom-tooltip">
        <span>
          Toda la documentación junto con la validación <br />
          de Control union para entregar para la certificación. <br />
          Disponible a partir del momento en que se declaran <br />
          las toneladas.
        </span>
      </ReactTooltip>
    </>
  );
};

export default ButtonDownload;
