import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./CreateEvidence.module.scss";
// import directus from "../../../utils/directus";
// import { uploadFiles } from "@directus/sdk";
import {
    CREATE_BATCH_EVIDENCE,
    CREATE_MAP_POINT_BATCH,
} from "../../../graphql/trace/getQrBatches";
import {useMutation} from "@apollo/client";
import {Formik, Field} from "formik";
import BatchEvidence from "./BatchEvidence";
import ProgressBar from "react-bootstrap/ProgressBar";
import {generateFileHash} from "../../../utils/file";
import axios from "axios";
import {Box, FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const CreateMapPoint = ({show, handleClose, batchId, refetch, mapId}) => {
    // const [createEvidence] = useMutation(CREATE_BATCH_EVIDENCE);
    const [createMapPoint] = useMutation(CREATE_MAP_POINT_BATCH);

    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(20);
    const [typeBatch, setTypeBatch] = useState();

    const typeBatchList = [
        {
            value: "origination",
            label: "Origination",
        },
        {
            value: "Process",
            label: "Process",
        },
        {
            value: "Transport",
            label: "transport",
        },
        {
            value: "wharehouse",
            label: "Whareouse",
        },
        {
            value: "trader",
            label: "Trader",
        },
        {
            value: "packer",
            label: "Packer",
        },
        {
            value: "manufacture",
            label: "Manufacture",
        },
    ];

    const onUploadProgress = (progressEvent) => {
        const {loaded, total} = progressEvent;
        const percentage = Math.floor((loaded * 100) / total);
        setProgress(percentage);
    };


    const handleFile = async (title, file) => {
        if (!file) {
            return null
        }
        setLoading(true);
        try {
            setProgress(0);
            const formData = new FormData();
            const config = {
                onUploadProgress
            }

            formData.append("title", title);
            formData.append("file", file);

            const resp = await axios.post(
                `${process.env.REACT_APP_HOST_SERVER_API}/files`,
                formData,
                config
            );

            const {data: dataFiles} = resp.data;
            setLoading(false);
            return dataFiles;
        } catch (error) {
            console.error(error);
        }
    };

    const handlePoint = async (values) => {
        if (values) {
            const image = await handleFile(`${batchId}_image`, values?.image);
            // const pdf = await handleFile(`${batchId}_pdf`, values?.pdf);
            const data = {
                nombre: values.nombre,
                descripcion: values.descripcion,
                image: image ?? undefined,
                // pdf: pdf ?? undefined,
                type: values.type,
                // url: values?.url,
                batch_id: {
                    id: batchId,
                },
                map_id: {
                    id: mapId,
                },
                location: [
                    {
                        lat: Number(values.latitud),
                        lng: Number(values.longitud),
                    },
                ],
            };
            try {
                await createMapPoint({
                    variables: {
                        data: data,
                    },
                });
            } catch (err) {
                console.log('createMapPoint')
                console.log(err)
            }
            handleClose();
            refetch();
        }
    };
    const initialValues = {
        nombre: "",
        descripcion: "",
        lat: null,
        lng: null,
        // url: "",
        image: null,
        // pdf: null,
        type: null,
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Formik initialValues={initialValues} onSubmit={handlePoint}>
                {({
                      values,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal.Header closeButton>
                            <h5
                                className="modal-title fontOne textDark w-100"
                                id="staticBackdropLabel"
                            >
                                Alta de punto en el mapa
                            </h5>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex align-items-center justify-content-between p-1">
                                <div className="flex-fill">
                                    <div className="input-group mb-3">
                    <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                    >
                      Nombre
                    </span>
                                        <input
                                            value={values.nombre}
                                            placeholder="Ingrese un nombre"
                                            className="form-control"
                                            name="nombre"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                    <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                    >
                      Descripción
                    </span>
                                        <input
                                            value={values.descripcion}
                                            placeholder="Ingrese una Descripción"
                                            className="form-control"
                                            name="descripcion"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                    <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                    >
                      Latitud
                    </span>
                                        <input
                                            value={values.lat}
                                            placeholder="Ingrese una latitud"
                                            className="form-control"
                                            type="number"
                                            name="latitud"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            step="0.000001"
                                        />
                                    </div>
                                    <div className="input-group mb-3">
                    <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                    >
                      Longitud
                    </span>
                                        <input
                                            value={values.lng}
                                            placeholder="Ingrese una longitud"
                                            className="form-control"
                                            name="longitud"
                                            type="number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            step="0.000001"
                                        />
                                    </div>
                    {/*                <div className="input-group mb-3">*/}
                    {/*<span*/}
                    {/*    className="input-group-text"*/}
                    {/*    id="inputGroup-sizing-default"*/}
                    {/*>*/}
                    {/*  URL*/}
                    {/*</span>*/}
                    {/*                    <input*/}
                    {/*                        value={values.url}*/}
                    {/*                        placeholder="Ingrese una URL"*/}
                    {/*                        className="form-control"*/}
                    {/*                        name="url"*/}
                    {/*                        onChange={handleChange}*/}
                    {/*                        onBlur={handleBlur}*/}
                    {/*                    />*/}
                    {/*                </div>*/}
                                    <div
                                        className={`${styles["custom-file-button"]} input-group mb-3`}
                                    >
                                        <label className="input-group-text" htmlFor="icon">
                                            Imagen
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            className="form-control"
                                            id="image"
                                            onChange={(e) =>
                                                setFieldValue("image", e.target.files[0])
                                            }
                                        />
                                    </div>
                                    {/*<div*/}
                                    {/*    className={`${styles["custom-file-button"]} input-group mb-3`}*/}
                                    {/*>*/}
                                    {/*    <label className="input-group-text" htmlFor="icon">*/}
                                    {/*        PDF*/}
                                    {/*    </label>*/}
                                    {/*    <input*/}
                                    {/*        type="file"*/}
                                    {/*        accept="pdf/*"*/}
                                    {/*        className="form-control"*/}
                                    {/*        id="pdf"*/}
                                    {/*        onChange={(e) => setFieldValue("pdf", e.target.files[0])}*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                    <div className="d-flex mb-3 w-32">
                                        <div className="input-group">
                                            <Field
                                                as="select"
                                                id="type"
                                                name="type"
                                                className="form-select"
                                            >
                                                <option value="" hidden>
                                                    Selecciona un tipo
                                                </option>
                                                {typeBatchList.map((item) => {
                                                    return (
                                                        <option value={item.value}>{item.label}</option>
                                                    );
                                                })}
                                                {/* <option value="option1">Opción 1</option>
                        <option value="option2">Opción 2</option>
                        <option value="option3">Opción 3</option> */}
                                            </Field>
                                        </div>
                                    </div>
                                    {/* <div
                    className={`${styles["custom-file-button"]} input-group mb-3`}
                  >
                    <label className="input-group-text" htmlFor="evidence">
                      Evidencia
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      className="form-control"
                      id="evidence"
                      onChange={(e) =>
                        setFieldValue("evidence", e.target.files[0])
                      }
                    />
                  </div> */}
                                    {/* <p>Al guardar la evidencia, automaticamente sera estampada en blockchain</p> */}
                                    {loading ? <ProgressBar animated now={progress}/> : null}
                                    {/* <div className={`${styles["custom-file-button"]} input-group mb-3`}>
                    <label className="input-group-text" htmlFor="ots">
                      Archivo OTS
                    </label>
                    <input type="file" accept=".ots" className="form-control" id="ots" onChange={(e) => setFieldValue("ots", e.target.files[0])} />
                  </div> */}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-around d-flex gap-2">
                            <button type="submit" className="btn btn-primary">
                                Guardar
                            </button>
                            {/* {edit ? (
                <button type="button" className="btn btn-outline-danger">
                  Eliminar
                </button>
              ) : null} */}
                        </Modal.Footer>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default CreateMapPoint;
