export const arrayJoin = (array) => {
  if (array.length === 0) return "";
  if (array.length === 1) return array[0];

  const allButFirst = array.slice(1);
  const allButFirstJoined = allButFirst.slice(0, -1).join(", ");
  const last = allButFirst.slice(-1)[0];

  if (allButFirstJoined) {
    return `${allButFirstJoined}, ${last}`;
  } else {
    return `${last}`;
  }
};
