import React from "react";

const BatchEvidence = ({ title, subtitle, icon, handleEditEvidence }) => {
  return (
    
    <li className="input-group mb-4">
      {icon && (
        <div className="input-group-text">
          <img src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${icon.id}`} alt={title} width="32" height="32" />
        </div>
      )}

      <div className="input-group-text flex-column align-items-start justify-content-center">
        <span>{title}</span>
        {subtitle ? <small className="text-muted">{subtitle}</small> : null}
      </div>

      {handleEditEvidence ? <i className="bi bi-pencil-fill my-3 ms-3" role="button" onClick={handleEditEvidence} /> : null}
    </li>
  );
};

export default BatchEvidence;
