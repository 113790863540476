import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoginAction } from "./actions/loginActions";

import Header from "./components/Header";
import Footer from "./components/Footer/Footer";
import LoginPage from "./components/login";
import Home from "./Views/Home";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Showcase from "./Views/Showcase";
import createApolloClient from "./config/apollo";
import { ApolloProvider } from "@apollo/client";

const App = () => {
  const dispatch = useDispatch();
  const client = createApolloClient();

  dispatch(isLoginAction());

  const token = useSelector((state) => {
    return state.token.token;
  });

  return (
    <ApolloProvider client={client}>
      <Router>
        {token ? (
          <>
            <Switch>
              <Route exact path="/">
                <Header />
                <Home />
                <Footer />
              </Route>
            </Switch>
          </>
        ) : (
          <>
            <Switch>
              <Route exact path="/">
                <Header />
                <LoginPage />
              </Route>
            </Switch>
          </>
        )}
        <Route path="/qr" component={Showcase} />
      </Router>
    </ApolloProvider>
  );
};

export default App;
