import { axiosCircuitExporter } from '../config/axios';
import {
  SAVE_PRODUCERS,
  SAVE_MATCHES,
  SAVE_TOTE,
  PAGING_PRODUCERS,
  LOADING_PRODUCERS,
} from '../types';

export function producersAction(user, payload = {}) {
  return async (dispatch, getState) => {
    try {
      const {
        paginationOptions: { page, numPerPage },
      } = getState().producers;
      const {
        search = '',
        filters = null,
        querySearch = '',
        farm = '',
        province = '',
        departments = [],
        licenseCampaign = '',
      } = payload;

      let parsedFilters = '';
      if (filters !== null) {
        parsedFilters = Object.entries(filters)
          .map((filter) => `${filter[0]}=${filter[1]}`)
          .join(',');
      }
      let parsedDepartments = '';
      if (Boolean(departments.length)) {
        parsedDepartments = departments.join(',');
      }

      dispatch(loadingProducers(true));

      const options = {
        method: 'GET',
        params: {
          page,
          numPerPage,
          search,
          filters: parsedFilters,
          querySearch,
          farm,
          province,
          departments: parsedDepartments,
          licenseCampaign,
        },
      };

      const { data } = await axiosCircuitExporter(
        `/v2/data-bi/identifier/${user?.identifier}`,
        options
      );

      const { data: dataList, matches, pagination, metaData } = data;

      dispatch(saveProducers(dataList));

      dispatch(saveMatches(matches));

      dispatch(saveToTes(metaData));

      let newPage = pagination.page + 1;
      let newNextPage = pagination.next !== null ? pagination.next + 1 : null;

      dispatch(pagingProducers({ page: newPage, next: newNextPage }));

      dispatch(loadingProducers(false));
    } catch (error) {
      console.log(error);
    }
  };
}

const saveProducers = (producers) => ({
  type: SAVE_PRODUCERS,
  payload: producers,
});

const saveMatches = (matches) => ({
  type: SAVE_MATCHES,
  payload: matches,
});

const pagingProducers = (options) => ({
  type: PAGING_PRODUCERS,
  payload: options,
});

const loadingProducers = (state) => ({
  type: LOADING_PRODUCERS,
  payload: state,
});

const saveToTes = (totes) => ({
  type: SAVE_TOTE,
  payload: totes,
});
