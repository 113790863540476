export const ADD_FILES = 'ADD_FILES';
export const ADD_FILES_ERROR = 'ADD_FILES_ERROR';

export const LOGIN = 'LOGIN';
export const START_LOGIN = 'START_LOGIN';
export const LOGIN_SUCESSFULL = 'LOGIN_SUCESSFULL';
export const LOGOUT = 'LOGOUT';
export const START_LOGOUT = 'START_LOGOUT';

export const SAVE_TOKEN = 'SAVE_TOKEN';
export const DEL_TOKEN = 'DEL_TOKEN';

export const SAVE_TOTE = 'SAVE_TOTE';
export const DEL_TOTE = 'DEL_TOTE';

export const SAVE_PRODUCERS = 'SAVE_PRODUCERS';
export const PAGING_PRODUCERS = 'PAGING_PRODUCERS';
export const LOADING_PRODUCERS = 'LOADING_PRODUCERS';
export const UPDATE_ONE_PRODUCER = 'UPDATE_ONE_PRODUCER';
export const SAVE_MATCHES = 'SAVE_MATCHES';
export const RESET_PRODUCERS = 'RESET_PRODUCERS';
export const RESET_PAGINATION = 'RESET_PAGINATION';

export const SAVE_TOTAL_FARMS = 'SAVE_TOTAL_FARMS';
