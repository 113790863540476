import React from 'react';
import specifiedIcon from '../../../Assets/ic_specified.svg';
import './specified-ton-button.css'


export const SpecifiedTonButton = ({ onClick }) => {


  return (
    <button className="specified-ton" onClick={onClick}>
      <span className="specified-ton-text">Reclamar toneladas</span>
      <img src={specifiedIcon} />
    </button>
  )
}
