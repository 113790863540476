import {gql} from "@apollo/client";

export const GET_QR_BATCHES = gql`
  query qr_by_id($id: ID!) {
    qr_by_id(id: $id) {
      name
      batches {
        id
        batch_number
        name
        quantity
        unit
        calibre
        lote_semilla
        variedad
        stage_id {
          id
        }
        points {
          nombre
          descripcion
          location {
            lat
            lng
          }
          map_id {
            id
          }
        }
        evidences {
          id
          title
          subtitle
          icon {
            id
            title
          }
          logo {
            id
            title
          }
          description
          evidence {
            id
            title
          }
          ots {
            id
            title
          }
          link
        }
      }
    }
  }
`;

export const CREATE_BATCH = gql`
  mutation create_batch_item($data: create_batch_input!) {
    create_batch_item(data: $data) {
      batch_number
      quantity
      unit
      calibre
      lote_semilla
      variedad
      qr_id {
        id
      }
      stage_id {
        id
      }
    }
  }
`;

export const EDIT_BATCH = gql`
  mutation update_batch_item($id: ID!, $data: update_batch_input!) {
    update_batch_item(id: $id, data: $data) {
      id
      batch_number
      quantity
      unit
      calibre
      lote_semilla
      variedad
      points {
        nombre
        descripcion
        type
        location {
          lat
          lng
        }
      }
    }
  }
`;

export const CREATE_BATCH_EVIDENCE = gql`
  mutation create_evidence_item($data: create_evidence_input!) {
    create_evidence_item(data: $data) {
      title
    }
  }
`;

export const CREATE_MAP_POINT_BATCH = gql`
  mutation Create_map_points_item($data: create_map_points_input!) {
    create_map_points_item(data: $data) {
      nombre
      descripcion
    }
  }
`;
