import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import './index.css';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' sx={{ color: '#4CAF50' }} />;
const dropDownIcon = (
  <KeyboardArrowDownRoundedIcon fontSize='medium' sx={{ color: '#495365' }} />
);

const AutoComplete = ({
  value,
  limitTags = 1,
  multiple = false,
  placeholder = '',
  options,
  isDisabled = false,
  onChange,
  noOptionsText = 'No hay opciones',
}) => {
  return (
    <Autocomplete
      multiple={multiple}
      limitTags={limitTags}
      disabled={isDisabled}
      popupIcon={dropDownIcon}
      noOptionsText={noOptionsText}
      options={options}
      value={value}
      onChange={onChange}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => {
        if (multiple) {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option}
            </li>
          );
        }
        return <li {...props}>{option}</li>;
      }}
      sx={{
        width: '100%',
        minHeight: '56px',
        background: '#FFFBFE',
        border: '1px solid #F4EFF4 !important',
        borderRadius: '8px',
      }}
      renderInput={(params) => (
        <TextField maxRows={0} {...params} placeholder={placeholder} />
      )}
    />
  );
};

AutoComplete.propTypes = {
  placeholder: PropTypes.string,
  limitTags: PropTypes.number,
  multiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  noOptionsText: PropTypes.string,
};

export default AutoComplete;
