import React from 'react';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

import './index.css';

const CampaingSelect = ({
  campaignsList = [],
  campaignSelected = '',
  onChangeCampaign,
}) => {
  return (
    <>
      <h2 className='secondTitle mb-0'>Campaña {campaignSelected}</h2>
      <Select
        value={campaignSelected}
        onChange={(event) => onChangeCampaign(event.target.value)}
        IconComponent={KeyboardArrowDownRoundedIcon}
        sx={{
          position: 'absolute',
          left: '300px',
          zIndex: 99,
        }}
      >
        {campaignsList.length &&
          campaignsList?.map((campaign, index) => (
            <MenuItem key={index} value={campaign}>
              Campaña {campaign}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

export default CampaingSelect;
