import { ADD_FILES, ADD_FILES_ERROR } from '../types';
import { axiosCircuitExporter } from '../config/axios';
import Swal from 'sweetalert2';

export function getReporstAction(data) {
  return async (dispatch) => {
    dispatch(getReporst());
    try {
      const { idHash, growerTaxId, farm, licenseCompanyTax } = data;
      const options = {
        method: 'GET',
        params: {
          exporterTaxId: licenseCompanyTax,
          producerTaxtId: growerTaxId,
          farm,
          idHash,
        },
        responseType: 'blob',
      };
      axiosCircuitExporter('/v2/reports', options)
        .then((response) => {
          const pdfFile = new Blob([response.data], {
            type: 'application/pdf',
          });

          const fileURL = URL.createObjectURL(pdfFile);

          const a = document.createElement('a');
          a.href = fileURL;
          a.download = 'report' || 'download';
          a.click();
        })
        .catch((error) => {
          const request = Object.values(error);
          if (request[2]?.data?.statusCode === 401)
            Swal.fire({
              icon: 'error',
              title: 'Sin autorizacion de usuario',
              text: 'Esta opcion solo esta disponible para aliados comerciales autorizados',
            });
          else
            Swal.fire({
              icon: 'error',
              title: 'Formato de documento invalido',
              text: 'Los datos del pedido deben contener exportador, cuit, establecimiento y nombre y apellido del propietario',
            });
        });
    } catch (error) {
      console.log(error);
      dispatch(getReporstError(true));

      Swal.fire({
        icon: 'error',
        title: 'Hubo un error',
        text: 'Hubo un error, intenta de nuevo',
      });
    }
  };
}

const getReporst = () => ({
  type: ADD_FILES,
});

const getReporstError = (estado) => ({
  type: ADD_FILES_ERROR,
  payload: estado,
});
