import { useState } from "react";
import Verification from "./Verification";
import { useMutation } from "@apollo/client";
import { EDIT_BATCH, CREATE_BATCH } from "../../../graphql/trace/getQrBatches";
import CreateEvidence from "./CreateEvidence";
import BatchEvidence from "./BatchEvidence";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import CreateMapPoint from "./CreateMapPoint";
import BatchPoint from "./BatchPoint";

function EditFormItem(props) {
  const {
    id,
    title, //Va en el header
    subtitle, //Va en el header grisado
    icon, // iconito a la izquierda de la tarjeta.
    heading, //Heading del primer item de body
    location, //bajada del primer item del body, va grisado.
    outputIcon, //outputIconn del producto. H: , W:
    by, //Empresa que realiza el proceso.
    logoBy, // LogoBy de la Empresa que realiza el proceso. Opcional
    inputs, //Que se usó para llegar al producto final.
    evidence, // Array con los objetos de evidencia.
    batch,
    batchEvidences,
    refetch,
    batchPoints,
    mapId,
  } = props;
  const { points } = batch;
  console.log('mapId', mapId)
  // const mapId = points.length ? points[0]?.map_id?.id : null;

  const [batchNumber, setBatchNumber] = useState(batch?.batch_number);
  const [volume, setVolume] = useState(batch?.quantity);
  const [unit, setUnit] = useState(batch?.unit);
  const [locationBatch, setLocationBatch] = useState({
    lat: null,
    lng: null,
  });
  const [typeBatch, setTypeBatch] = useState();

  const [editBatch] = useMutation(EDIT_BATCH);
  const [createBatch] = useMutation(CREATE_BATCH);

  const [show, setShow] = useState(false);
  const [showModalMapPoint, setShowModalMapPoint] = useState(false);

  const [edit, setEdit] = useState(false);
  const [evidenceToEdit, setEvidenceToEdit] = useState({});
  const [pointToEdit, setPointToEdit] = useState({});

  const typeBatchList = [
    {
      value: "origination",
      label: "Origination",
    },
    {
      value: "process",
      label: "Process",
    },
    {
      value: "Transport",
      label: "transport",
    },
  ];

  const handleClose = () => {
    setEdit(false);
    setEvidenceToEdit({});
    setShow(false);
    setShowModalMapPoint(false);
  };
  const handleShow = () => setShow(true);

  const { qrId } = useParams();

  const handleSubmitBatch = async () => {
    try {
      if (!!batch) {
        const data = {
          batch_number: batchNumber,
          quantity: +volume,
          unit: unit,
          points: {
            nombre: "LatLng",
            descripcion: "DescripLatLng",
            type: "origination",
            location: {
              lat: locationBatch.lat,
              lng: locationBatch.lng,
            },
          },
        };
        await editBatch({
          variables: {
            id: batch?.id,
            data: data,
          },
        });
        refetch();
        alert("El batch se editó correctamente");
      } else {
        const data = {
          batch_number: batchNumber,
          quantity: +volume,
          unit: unit,
          qr_id: {
            id: qrId,
          },
          stage_id: {
            id: id,
          },
        };
        await createBatch({
          variables: {
            data: data,
          },
        });
        refetch();
        alert("El batch se creó correctamente");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleEditEvidence = (evidence) => {
    setEvidenceToEdit(evidence);
    setShow(true);
    setEdit(true);
  };
  const [age, setAge] = useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      <div className="row">
        <div className="col-md-7">
          <div className="card  my-3">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between p-1">
                <div className="flex-fill">
                  <p>Definir Lote: {title}</p>
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      #
                    </span>
                    <input
                      value={batchNumber}
                      className="form-control"
                      name="batchNumber"
                      onChange={(e) => setBatchNumber(e.target.value)}
                    />
                  </div>
                  <div className="d-flex mb-3">
                    <div className="input-group me-3">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        Cantidad
                      </span>
                      <input
                        value={volume}
                        type="number"
                        className="form-control"
                        name="volume"
                        onChange={(e) => setVolume(e.target.value)}
                      />
                    </div>
                    <div className="input-group ">
                      <span
                        className="input-group-text"
                        id="inputGroup-sizing-default"
                      >
                        Unidad
                      </span>
                      <input
                        value={unit}
                        type="text"
                        className="form-control"
                        name="unit"
                        onChange={(e) => setUnit(e.target.value)}
                      />
                    </div>
                  </div>

                  {/*<div className="d-flex mb-3">*/}
                  {/*  <div className="input-group me-3">*/}
                  {/*    <span*/}
                  {/*      className="input-group-text"*/}
                  {/*      id="inputGroup-sizing-default"*/}
                  {/*    >*/}
                  {/*      Lat*/}
                  {/*    </span>*/}
                  {/*    <input*/}
                  {/*      value={locationBatch.lat}*/}
                  {/*      type="number"*/}
                  {/*      className="form-control"*/}
                  {/*      name="lat"*/}
                  {/*      onChange={(e) =>*/}
                  {/*        setLocationBatch({*/}
                  {/*          ...locationBatch,*/}
                  {/*          lat: e.target.value,*/}
                  {/*        })*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*  <div className="input-group ">*/}
                  {/*    <span*/}
                  {/*      className="input-group-text"*/}
                  {/*      id="inputGroup-sizing-default"*/}
                  {/*    >*/}
                  {/*      Lng*/}
                  {/*    </span>*/}
                  {/*    <input*/}
                  {/*      value={locationBatch.lng}*/}
                  {/*      type="text"*/}
                  {/*      className="form-control"*/}
                  {/*      name="lng"*/}
                  {/*      onChange={(e) =>*/}
                  {/*        setLocationBatch({*/}
                  {/*          ...locationBatch,*/}
                  {/*          lng: e.target.value,*/}
                  {/*        })*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {!!batch ? (
                    <>
                      {batchEvidences?.length ? <p>Evidencias: </p> : null}
                      {batchEvidences?.map(({ evidence_id }, index) => {
                        return (
                          <div key={index}>
                            <BatchEvidence
                              title={evidence_id.title}
                              subtitle={evidence_id.subtitle}
                              icon={evidence_id.icon}
                              handleEditEvidence={() =>
                                handleEditEvidence(evidence_id)
                              }
                            />
                          </div>
                        );
                      })}
                      {batchPoints?.length ? <p>Puntos en el mapa: </p> : null}
                      <div className="row">
                        {batchPoints?.map(({ evidence_id }, index) => {
                          return (
                            <div className="col-4" key={index}>
                              <BatchPoint
                                title={evidence_id.nombre}
                                subtitle={evidence_id.descripcion}
                                icon={evidence_id.icon}
                                lat={evidence_id.location[0].lat}
                                lng={evidence_id.location[0].lng}
                              />
                            </div>
                          );
                        })}
                      </div>

                      <div className="my-3">
                        <button
                          className="btn btn-secondary"
                          onClick={handleShow}
                        >
                          <i className="bi bi-cloud-arrow-up-fill m-1" />
                          <span>Nueva Evidencia</span>
                        </button>
                      </div>
                    </>
                  ) : null}

                  <div className="d-flex justify-content-between ">
                    <button
                      onClick={handleSubmitBatch}
                      className="btn btn-primary"
                    >
                      Guardar Cambios
                    </button>
                    <div>
                      <button
                        onClick={() => setShowModalMapPoint(true)}
                        className="btn btn-primary"
                      >
                        Crear Punto en el Mapa
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md">
          <div className="card border-light shadow my-3">
            <div className="card-header bg-transparent border-ligh">
              {title} <small className="text-muted">{batchNumber}</small>
            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between p-1">
                {outputIcon && (
                  <img
                    src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${outputIcon}`}
                    className="m-2"
                    alt=""
                    width="60"
                    height="60"
                  />
                )}
                <div className="flex-fill">
                  <p className="card-title mb-0">
                    {volume && unit
                      ? `${volume} ${unit} de ${heading}`
                      : heading}
                  </p>
                  <p className="card-subtitle mb-2 text-muted">
                    <small>{location}</small>
                  </p>
                </div>
              </div>

              {inputs && (
                <div className="d-flex align-items-center justify-content-between p-1">
                  <div className="flex-fill">
                    <p className="card-title text-muted mb-0">
                      <small>Hecho con</small>
                    </p>
                    <p className="card-subtitle fw-light mb-2">{inputs}</p>
                  </div>
                </div>
              )}
              {by && (
                <div className="d-flex align-items-center justify-content-between p-1">
                  {logoBy && (
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${logoBy}`}
                      className="m-2"
                      alt=""
                      width="32"
                      height="32"
                    />
                  )}
                  <div className="flex-fill">
                    <p className="card-subtitle text-muted mb-0">
                      <small>Hecho por</small>
                    </p>
                    <p className="card-title mb-2">{by}</p>
                  </div>
                </div>
              )}
              {evidence && <Verification id={id} data={evidence} />}
            </div>
          </div>
        </div>
      </div>
      {show && (
        <CreateEvidence
          show={show}
          handleClose={handleClose}
          edit={edit}
          evidenceToEdit={evidenceToEdit}
          batchId={batch?.id}
          refetch={refetch}
        />
      )}
      {showModalMapPoint && (
        <CreateMapPoint
          show={showModalMapPoint}
          handleClose={handleClose}
          batchId={batch?.id}
          mapId={mapId}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default EditFormItem;
