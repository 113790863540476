import { SAVE_TOTE, DEL_TOTE } from "../types";

const initialState = {
  totes: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_TOTE:
      return {
        ...state,       
        totes: action.payload
      };
    case DEL_TOTE:
      return {
        ...state,
        totes: null,
      };
    default:
      return state;
  }
}
