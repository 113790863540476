import React from "react";

const BatchPoint = ({ title, subtitle, lat, lng }) => {
  return (
    <li className="input-group py-1">
      <div className="input-group-text d-flex flex-column align-items-start justify-content-center  ">
        <span className="fw-bold text-truncate">Nombre:</span>{" "}
        <span className="text-truncate">{title}</span>
        <div className=" d-flex  align-items-start justify-content-center gap-1 ">
          <p>
            <span className="fw-bold">Lat:</span> {lat.toFixed(1)}
          </p>
          <p>
            <span className="fw-bold">Lng:</span> {lng.toFixed(1)}
          </p>
        </div>
      </div>
    </li>
  );
};

export default BatchPoint;
