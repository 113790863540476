import React from "react";
import arrowLeft from "../../../Assets/ArrowLeft.svg";
import { Typography, Box } from "@mui/material";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useQuery } from "@apollo/client";
import { GET_QRS } from "../../../graphql/getQrs";

const CreateStages = () => {
  const { productId } = useParams();
  const history = useHistory();

  const { data: { product_by_id } = {} } = useQuery(GET_QRS, {
    variables: {
      id: productId,
    },
  });

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        pt: 4,
        px: 7,
      }}
    >
      <Tooltip title="Regresar" sx={{ mr: 2, mb: 1, pl: 0 }}>
        <IconButton>
          <Box component="img" src={arrowLeft} alt="edit-icon" width={24} onClick={() => history.goBack()} />
        </IconButton>
      </Tooltip>

      <Typography component="h1" variant="h6" sx={{ fontSize: 24, fontWeight: 400, mb: 1 }}>
        Trazabilidad /
        <Typography component="span" variant="h6" sx={{ fontSize: 24, fontWeight: 700, mb: 1 }}>
          {product_by_id && ` ${product_by_id.name}`}
        </Typography>
      </Typography>
      <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 400, mb: 6 }}>
        Editar Stages
      </Typography>
    </Box>
  );
};

export default CreateStages;
