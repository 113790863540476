import React, {useEffect, useRef, useState} from "react";
import ReactTooltip from "react-tooltip";
import './style.css'
import parseUrlSSL from "../../../utils/parse-url";

const ChipDDSS = ({agreement, hasAgreement, existValueTon, displayAlertRequestTons}) =>{

  const chipDDSSRef = useRef(`DDSS_${Date.now()}`)
  const [existAgreement, setExistAgreement] = useState(false)

  const handlerClick = (event) => {
    event.preventDefault()
    if(hasAgreement && !Boolean(existValueTon)){
      displayAlertRequestTons()
    }

  }

  useEffect(() =>{
    setExistAgreement(Boolean(agreement))

  }, [agreement])

  const COLOR = hasAgreement ? '#4CAF50' : '#C2C2C2'
  return (
    <>
      <ReactTooltip id={chipDDSSRef.current} className="custom-tooltip">
        <span>
          Autodeclaración de Sustentabilidad. <br/>
          Se muestra la disponibilidad para  <br/>
          descarga una vez que se solicita el campo.
        </span>
      </ReactTooltip>
      <div className={`documentChip ${hasAgreement ? 'available' : ''}`} data-tip data-for={chipDDSSRef.current}>
        <div className="chipIcon">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.73908 13.71C9.83797 13.801 9.95457 13.8724 10.0822 13.92C10.3353 14.02 10.6193 14.02 10.8724 13.92C11.0001 13.8724 11.1167 13.801 11.2156 13.71L14.3349 10.71C14.5307 10.5217 14.6407 10.2663 14.6407 10C14.6407 9.7337 14.5307 9.47831 14.3349 9.29C14.1391 9.1017 13.8735 8.99591 13.5966 8.99591C13.3197 8.99591 13.0542 9.1017 12.8584 9.29L11.5171 10.59V7C11.5171 6.73479 11.4075 6.48043 11.2125 6.2929C11.0176 6.10536 10.7531 6 10.4773 6C10.2016 6 9.93708 6.10536 9.74209 6.2929C9.54709 6.48043 9.43754 6.73479 9.43754 7V10.59L8.09624 9.29C7.99958 9.19628 7.88458 9.12188 7.75787 9.07111C7.63117 9.02034 7.49526 8.99421 7.358 8.99421C7.22074 8.99421 7.08483 9.02034 6.95813 9.07111C6.83142 9.12188 6.71642 9.19628 6.61976 9.29C6.5223 9.38297 6.44495 9.49357 6.39216 9.61543C6.33938 9.73729 6.3122 9.86799 6.3122 10C6.3122 10.132 6.33938 10.2627 6.39216 10.3846C6.44495 10.5064 6.5223 10.617 6.61976 10.71L9.73908 13.71ZM10.4773 20C12.5338 20 14.5441 19.4135 16.254 18.3147C17.9639 17.2159 19.2966 15.6541 20.0836 13.8268C20.8705 11.9996 21.0765 9.98891 20.6753 8.0491C20.2741 6.10929 19.2838 4.32746 17.8296 2.92894C16.3755 1.53041 14.5228 0.578004 12.5058 0.192152C10.4889 -0.193701 8.39822 0.00433284 6.49828 0.761209C4.59834 1.51809 2.97444 2.79981 1.83192 4.4443C0.689406 6.08879 0.0795898 8.02219 0.0795898 10C0.0795898 12.6522 1.17506 15.1957 3.12501 17.0711C4.09053 17.9997 5.23677 18.7363 6.49828 19.2388C7.75979 19.7413 9.11187 20 10.4773 20ZM10.4773 2C12.1225 2 13.7307 2.4692 15.0987 3.34825C16.4666 4.2273 17.5327 5.47673 18.1623 6.93854C18.7919 8.40035 18.9566 10.0089 18.6357 11.5607C18.3147 13.1126 17.5225 14.538 16.3592 15.6569C15.1958 16.7757 13.7137 17.5376 12.1001 17.8463C10.4865 18.155 8.81404 17.9965 7.29409 17.391C5.77414 16.7855 4.47502 15.7602 3.561 14.4446C2.64699 13.129 2.15914 11.5823 2.15914 10C2.15914 7.87827 3.03551 5.84344 4.59547 4.34315C6.15544 2.84286 8.2712 2 10.4773 2Z"
                  fill={COLOR}/>
          </svg>
        </div>
        {
          existAgreement && existValueTon ? (
              <a href={parseUrlSSL(agreement)}
                 target="_blank" style={{color: COLOR, 'text-decoration': 'none'}}
              >
                <span className="chipText">DDSS</span>
              </a>
          ) : (
              <span className="chipText" onClick={handlerClick}>DDSS</span>
          )
        }
      </div>
    </>
  )

}


export default ChipDDSS
