import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/client";
import { GET_BRAND } from "../graphql/getBrand";
import { Route, Switch, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import ListProducts from "../components/QR/Showcase/ListProducts";
import ListQrs from "../components/QR/Showcase/ListQrs";
import EditQR from "../components/QR/Showcase/EditQR";
import CreateQR from "../components/QR/Showcase/CreateQR";
import CreateStages from "../components/QR/Showcase/CreateStages";
import { useQuery as useParams } from "../hooks/useQuery";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  typography: {
    fontFamily: "Inter, sans-serif",
    button: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: "none",
    },
  },
});

export default function Showcase() {
  const match = useRouteMatch();
  const query = useParams();
  const cuit = query.get("cuit") ? query.get("cuit") : "";

  const { data: { brand } = {} } = useQuery(GET_BRAND, {
    variables: {
      cuit: cuit,
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Switch>
          <Route exact path={`${match.path}/:productId`}>
            <ListQrs />
          </Route>
          <Route exact path={`${match.path}/:productId/stages`}>
            <CreateStages />
          </Route>
          <Route exact path={`${match.path}/:productId/create`}>
            <CreateQR />
          </Route>
          <Route exact path={`${match.path}/edit/:qrId`}>
            <EditQR />
          </Route>
          <Route path={match.path}>
            <ListProducts products={brand && brand.length > 0 ? brand[0].products : null} />
          </Route>
        </Switch>
      </Box>
    </ThemeProvider>
  );
}
