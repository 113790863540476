import React, { useState } from "react";
import editIcon from "../../../Assets/Edit.svg";
import arrowLeft from "../../../Assets/ArrowLeft.svg";
import { Typography, Box, Fab, Button } from "@mui/material";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "@apollo/client";
import { GET_QRS } from "../../../graphql/getQrs";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import { green } from "@mui/material/colors";
import { useQuery as useQueryParams } from "../../../hooks/useQuery";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ListQrs = () => {
  const { productId } = useParams();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [encodedUrlState, setEncodedUrlState] = useState("");
  const query = useQueryParams();
  const role = query.get("role") ? query.get("role") : "";

  const { data: { product_by_id } = {} } = useQuery(GET_QRS, {
    variables: {
      id: productId,
    },
  });

  const handleClick = (qrId) => {
    handleOpen();
    const queryParams = `${encodeURIComponent("qr")}=${encodeURIComponent(qrId)}`;
    const qrPage = `${product_by_id.website_url}/?${queryParams}`.replace(/%26/g, "&");
    setEncodedUrlState(qrPage);
    setQrCodeUrl(`https://qrcode.tec-it.com/API/QRCode?data=${product_by_id.website_url}%2F%3F${queryParams}`);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        pt: 4,
        px: 7,
      }}
    >
      <Tooltip title="Regresar" sx={{ mr: 2, mb: 1, pl: 0 }}>
        <IconButton>
          <Box component="img" src={arrowLeft} alt="edit-icon" width={24} onClick={() => history.goBack()} />
        </IconButton>
      </Tooltip>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography component="h1" variant="h6" sx={{ fontSize: 24, fontWeight: 400, mb: 1 }}>
            Trazabilidad /
            <Typography component="span" variant="h6" sx={{ fontSize: 24, fontWeight: 700, mb: 1 }}>
              {product_by_id && ` ${product_by_id.name}`}
            </Typography>
          </Typography>
          <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 400 }}>
            Edita las características y los logros sostenibles desde su orígen hasta su transporte, procesamiento y fabricación.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {role === "QR_ADMIN" ? (
            <Button
              variant="contained"
              href={`/qr/${productId}/create?productId=${productId}`}
              sx={{ backgroundColor: green[500], "&:hover": { bgcolor: green[600], color: "#FFF" } }}
            >
              Crear nuevo QR
            </Button>
          ) : null}
        </Box>
      </Box>
      <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 700, color: "#687083", mb: 1, mt: 6 }}>
        Listado de QR
      </Typography>

      {product_by_id
        ? product_by_id.qrs.map((qr, index) => (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                background: "#fafafa",
                borderRadius: "8px",
                border: "1px solid #ebebeb",
                fontSize: 14,
                alignItems: "stretch,",
                justifyContent: "space-between",
                padding: "20px",
                marginBottom: 2,
              }}
              key={index}
            >
              <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 700 }}>
                QR ID:
                <Typography component="span" variant="h6" sx={{ fontSize: 16, fontWeight: 400, mb: 1, pr: 2 }}>
                  {` ${qr.id}`}
                </Typography>
                {qr.name ? (
                  <>
                    Nombre:
                    <Typography component="span" variant="h6" sx={{ fontSize: 16, fontWeight: 400, mb: 1 }}>
                      {` ${qr.name}`}
                    </Typography>
                  </>
                ) : null}
              </Typography>
              <div>
                {role === "QR_ADMIN" ? (
                  <Tooltip title="Editar QR" sx={{ mr: 3, py: 0 }}>
                    <Link href={`/qr/edit/${qr.id}?productId=${productId}`}>
                      <Box component="img" src={editIcon} alt="edit-icon" width={24} />
                    </Link>
                  </Tooltip>
                ) : null}

                <Tooltip title="Ver codigo QR" sx={{ mr: 3, py: 0 }} onClick={() => handleClick(qr.id)}>
                  <QrCodeIcon width={24} />
                </Tooltip>
                <Tooltip title="Ver historia del producto" sx={{ py: 0 }}>
                  <Link href={`${product_by_id.website_url}/?qr=${qr.id}`} target="_blank" rel="noopener noreferrer" color="inherit">
                    <ArrowOutwardIcon width={24} />
                  </Link>
                </Tooltip>

                {/* <Link to={`/qr/${qr.id}`}> */}

                {/* </Link> */}
              </div>
            </Box>
          ))
        : null}

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <a href={`${encodedUrlState}`} target="_blank" rel="noreferrer">
            <img src={qrCodeUrl} width="150" height="150" />
          </a>
        </Box>
      </Modal>
    </Box>
  );
};

export default ListQrs;
