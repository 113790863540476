import { SAVE_PRODUCERS, UPDATE_ONE_PRODUCER } from '../types';

const saveProducers = (payload) => ({
  type: SAVE_PRODUCERS,
  payload,
});

const updateOneProducer = (payload) => ({
  type: UPDATE_ONE_PRODUCER,
  payload,
});

export { saveProducers, updateOneProducer };
