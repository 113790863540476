import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import Loading from "../Loading/Loading";
import { producersAction } from "../../actions/producersAction";
import { PAGING_PRODUCERS, RESET_PRODUCERS } from "../../types";

const SearchList = ({
  searchQuery,
  inputIsFocus,
  setsearchQuery,
  paramsToFilter,
  setparamsToFilter,
  setInputIsFocus,
}) => {
  const [ListWithUniqueItems, setListWithUniqueItems] = useState([]);

  const dispatch = useDispatch();
  const userActual = useSelector((state) => {
    return state.token;
  });
  const { user } = userActual;

  const producers = useSelector((state) => {
    return state.producers.producers;
  });
  const matches = useSelector((state) => {
    return state.producers.matches;
  });
  useEffect(() => {
    if (producers.length > 0 && matches.length > 0) {
      // delete duplicates producers with grower_name and grower_cuit equlal to the same
      const uniqueProducers = matches?.filter((producer, index) => {
        return (
          matches?.findIndex(
            (p) =>
              p.grower_name === producer.grower_name &&
              p.grower_cuit === producer.grower_cuit
          ) === index
        );
      });
      setListWithUniqueItems(uniqueProducers);
    } else {
      setListWithUniqueItems([]);
    }
  }, [matches, producers]);

  const loading = useSelector((state) => {
    return state.producers.loading;
  });
  const handleSetOneProducer = (producer) => {
    // verify if is posible convert to number searchQuery
    if (Number.isNaN(Number(searchQuery))) {
      if (searchQuery !== producer.grower_name) {
        setsearchQuery(producer.grower_name);
        setparamsToFilter((prevValues) => ({
          ...prevValues,
          querySearch: producer.grower_name,
        }));
        dispatch(pagingProducers({ page: 1, next: null }));
        dispatch(resetProducers());
        dispatch(
          producersAction(user, {
            ...paramsToFilter,
            querySearch: producer.grower_name,
          })
        );
      }
      setInputIsFocus(false);
    } else {
      if (searchQuery !== producer.grower_cuit) {
        setsearchQuery(producer.grower_cuit);
        setparamsToFilter((prevValues) => ({
          ...prevValues,
          querySearch: producer.grower_cuit,
        }));
        dispatch(pagingProducers({ page: 1, next: null }));
        dispatch(resetProducers());
        dispatch(
          producersAction(user, {
            ...paramsToFilter,
            querySearch: producer.grower_cuit,
          })
        );
      }
      setInputIsFocus(false);
    }
  };

  const pagingProducers = (options) => ({
    type: PAGING_PRODUCERS,
    payload: options,
  });

  const resetProducers = () => ({
    type: RESET_PRODUCERS,
  });

  return (
    <>
      {searchQuery?.length > 0 &&
      ListWithUniqueItems?.length > 0 &&
      inputIsFocus ? (
        <div className="searchQueryContainer" show-list="show">
          {ListWithUniqueItems.map((producer, index) => {
            return (
              <div
                className="searchListItem"
                key={producer?.id_table ? producer?.id_table : index}
                onClick={() => handleSetOneProducer(producer)}
              >
                <Highlighter
                  highlightStyle={{ fontWeight: "500", background: "none" }}
                  searchWords={[searchQuery]}
                  autoEscape={false}
                  textToHighlight={`${producer?.grower_name
                    ?.toLowerCase()
                    ?.replace(/\b\w/g, (l) => l.toUpperCase())} ${
                    producer?.grower_cuit
                  }`}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {searchQuery?.length > 1 && inputIsFocus && (
            <div
              className="searchQueryContainer"
              style={{ overflow: "hidden" }}
            >
              {loading ? (
                <Loading />
              ) : (
                <span>No se encontraron coincidencias</span>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SearchList;
