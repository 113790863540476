import { SAVE_TOKEN, DEL_TOKEN, LOGIN_SUCESSFULL } from "../types";

const initialState = {
  token: null,
  loading: false,
  user: null,
  totalFarms: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "START_LOGIN":
      return {
        ...state,
        loading: action.payload,
      };
    case SAVE_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        authToken: action?.payload?.authToken,
        isApp: action?.payload?.isApp,
        totalFarms: action.payload.totalFarms,
      };
    case DEL_TOKEN:
      return {
        ...state,
        token: null,
      };
    case LOGIN_SUCESSFULL:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        user: action.payload.user,
        authToken: action?.payload?.authToken,
        isApp: action?.payload?.isApp,
      };
    case "START_LOGOUT":
      return {
        ...state,
        loading: false,
        token: null,
        user: null,
        authToken: null,
        isApp: null,
      };

    default:
      return state;
  }
}
