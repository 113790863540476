import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { loginAction } from "../actions/loginActions";

import Swal from "sweetalert2";
import logoUcropitDark from "../images/logoUcropit.svg";
import { regexEmail } from "../commons/constants";
import { useQuery } from "../hooks";
import Loading from "./Loading/Loading";

const loginValidation = (data) => {
  const { email, password } = data;
  if (!email) return { msg: "Por favor ingresa tu correo electrónico." };
  if (!regexEmail.test(email))
    return { msg: "Ingresa un correo electrónico válido." };
  if (password.length <= 4)
    return { msg: "Su contraseña debe contener entre 6 y 8 caracteres" };
  if (password.length >= 9)
    return { msg: "Su contraseña debe contener entre 6 y 8 caracteres" };
  return null;
};

const LoginPage = () => {
  const dispatch = useDispatch();
  let [email, saveEmail] = useState("");
  let [password, savePassword] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const query = useQuery();

  useEffect(() => {
    decodeFormTokenAndLogin();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitNewData = (e) => {
    e.preventDefault();

    const dataToSend = {
      email,
      password,
    };

    const validation = loginValidation(dataToSend);

    if (validation) {
      Swal.fire({
        icon: "error",
        title: "Ingrese datos nuevamente",
        text: validation.msg,
        confirmButtonColor: "#96C24D",
      });
      return;
    }
    dispatch(loginAction(dataToSend));
  };

  const decodeFormTokenAndLogin = async () => {
    const tokenFromApp = query.get("tokenFromApp");
    const authToken = query.get("authToken");

    if (tokenFromApp) {
      const data = JSON.parse(window.atob(tokenFromApp));

      if (data?.hasOwnProperty("email") && data?.hasOwnProperty("password")) {
        const dataToSend = {
          ...data,
          authToken,
          isApp: true,
        };

        await dispatch(loginAction(dataToSend));
      }
    }
    setIsLoading(false);
  };

  if (isLoading) return <Loading />;

  return (
    <div className="">
      <main className="form-signin text-center">
        <img src={logoUcropitDark} alt="ucropit logo" />
        <form onSubmit={submitNewData}>
          <h1 className="h4 mb-3 fw-normal">Por favor inicie sesión</h1>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => saveEmail(e.target.value)}
            ></input>
            <label htmlFor="floatingInput">Correo electrónico</label>
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              value={password}
              onChange={(e) => savePassword(e.target.value)}
            ></input>
            <label htmlFor="floatingPassword">Contraseña</label>
          </div>

          <button
            className="w-100 btn btn-lg color btn-color-ucropit"
            type="submit"
          >
            Ingresar
          </button>
        </form>
      </main>
    </div>
  );
};

export default LoginPage;
