
import { useEffect, useRef, useState } from 'react';
export default function useNearScreen({ distance = '100px', externalRef, once = true } = {}) {
  const fromRef = useRef();
  const [isNearScreen, setshotw] = useState(false)

  useEffect(() => {
    let observer

    const element = externalRef ? externalRef.current : fromRef.current;
    const onChange = (entries) => {
      const el = entries[0]
      if (el.isIntersecting) {
        setshotw(true)
        once && observer.disconnect()
      } else {
        !once && setshotw(false)
      }
    }
    observer = new IntersectionObserver(onChange, {
      rootMargin: distance,
    })

    if (element) observer.observe(element)
    return () => observer && observer.disconnect()
  }, [])

  return { fromRef, isNearScreen }
}