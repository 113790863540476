import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./CreateEvidence.module.scss";
// import directus from "../../../utils/directus";
// import { uploadFiles } from "@directus/sdk";
import { CREATE_BATCH_EVIDENCE } from "../../../graphql/trace/getQrBatches";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import BatchEvidence from "./BatchEvidence";
import ProgressBar from "react-bootstrap/ProgressBar";
import { generateFileHash } from "../../../utils/file";
import axios from "axios";

const CreateEvidence = ({
  show,
  handleClose,
  edit,
  evidenceToEdit,
  batchId,
  refetch,
}) => {
  const [createEvidence] = useMutation(CREATE_BATCH_EVIDENCE);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(20);

  const initialValues = {
    title: evidenceToEdit ? evidenceToEdit.title : "",
    subtitle: evidenceToEdit ? evidenceToEdit.subtitle : "",
    description: evidenceToEdit ? evidenceToEdit.description : "",
    icon: evidenceToEdit ? evidenceToEdit.icon : "",
    logo: evidenceToEdit ? evidenceToEdit.logo : "",
    evidence: evidenceToEdit ? evidenceToEdit.evidence : "",
    ots: evidenceToEdit ? evidenceToEdit.ots : "",
  };

  const onUploadProgress = (progressEvent) => {
    const {loaded, total} = progressEvent;
    const percentage = Math.floor((loaded * 100) / total);
    setProgress(percentage);
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    if (!edit) {
      try {
        setProgress(0);
        const formData = new FormData();
        if (values.icon) {
          formData.append("title", `${batchId}_icon`);
          formData.append("file", values.icon);
        }
        if (values.logo) {
          formData.append("title", `${batchId}_logo`);
          formData.append("file", values.logo);
        }
        if (values.evidence) {
          formData.append("title", `${batchId}_evidence`);
          formData.append("file", values.evidence);

          const hashBuffer = await generateFileHash(values.evidence);
          const hashString = hashBuffer.toString("hex");
          // setProgress(40);

          const resp = await axios.post(
            `${process.env.REACT_APP_STAMP_MS_API}/api/stamp`,
            {
              hash: hashString,
            }
          );
          // setProgress(60);

          const otsBlob = new Blob([resp.data.fileOts], {
            type: "octet/stream",
          });

          if (otsBlob) {
            formData.append("title", `${batchId}_ots`);
            formData.append("file", otsBlob, values.evidence.name + ".ots");
          }
        }
        let result = [];
        let res;
        if (values.icon || values.logo || values.evidence) {
          res = await axios.post(
            `${process.env.REACT_APP_HOST_SERVER_API}/files`,
            formData
          );
          // result = await directus.request(uploadFiles(formData));
        }

        setProgress(95);
        const { data: dataFiles } = res.data;
        const evidencePdf = dataFiles.find(
          (item) => item.type === "application/pdf"
        );
        const evidenceOts = dataFiles.find(
          (item) => item.type === "octet/stream"
        );
        const icon = dataFiles.find(
            (item) => item.title === `${batchId}_icon`
        );
        const logo = dataFiles.find(
            (item) => item.title === `${batchId}_logo`
        );
        const data = {
          title: values.title,
          subtitle: values.subtitle,
          description: values.description,
          batch_id: {
            id: batchId,
          },
          evidence: evidencePdf ?? undefined,
          ots: evidenceOts ?? undefined,
          icon: icon ?? undefined,
          logo: logo ?? undefined,
        };

        if (Array.isArray(result)) {
          result?.forEach((obj) => {
            const parts = obj.title.split("_");
            if (parts.length >= 2) {
              const identifier = parts[parts.length - 1];
              data[identifier] = obj;
            }
          });
        }

        await createEvidence({
          variables: {
            data: data,
          },
        });

        setProgress(100);
        setLoading(false);

        refetch();
        handleClose();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <h5
                className="modal-title fontOne textDark w-100"
                id="staticBackdropLabel"
              >
                Alta de evidencia para batch
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center justify-content-between p-1">
                <div className="flex-fill">
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      Title
                    </span>
                    <input
                      value={values.title}
                      placeholder="Ingrese un titulo"
                      className="form-control"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <span
                      className="input-group-text"
                      id="inputGroup-sizing-default"
                    >
                      Subtitle
                    </span>
                    <input
                      value={values.subtitle}
                      placeholder="Ingrese un subtitulo"
                      className="form-control"
                      name="subtitle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  {evidenceToEdit.icon ? (
                    <BatchEvidence
                      icon={values.icon}
                      title={values.icon?.title}
                      // handleEditEvidence={(e) => setFieldValue("icon", e.target.files[0])}
                    />
                  ) : (
                    <div
                      className={`${styles["custom-file-button"]} input-group mb-3`}
                    >
                      <label className="input-group-text" htmlFor="icon">
                        Icono
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        id="icon"
                        onChange={(e) =>
                          setFieldValue("icon", e.target.files[0])
                        }
                      />
                    </div>
                  )}

                  {evidenceToEdit.logo ? (
                    <BatchEvidence
                      icon={values.logo}
                      title={values.logo?.title}
                      // handleEditEvidence={(e) => setFieldValue("icon", e.target.files[0])}
                    />
                  ) : (
                    <div
                      className={`${styles["custom-file-button"]} input-group mb-3`}
                    >
                      <label className="input-group-text" htmlFor="logo">
                        Logo
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        className="form-control"
                        id="logo"
                        onChange={(e) =>
                          setFieldValue("logo", e.target.files[0])
                        }
                      />
                    </div>
                  )}

                  <p className="mb-2">Descripción</p>
                  <div className="input-group mb-3">
                    <textarea
                      value={values.description}
                      className="form-control"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div
                    className={`${styles["custom-file-button"]} input-group mb-3`}
                  >
                    <label className="input-group-text" htmlFor="evidence">
                      Evidencia
                    </label>
                    <input
                      type="file"
                      accept=".pdf"
                      className="form-control"
                      id="evidence"
                      onChange={(e) =>
                        setFieldValue("evidence", e.target.files[0])
                      }
                    />
                  </div>
                  {/* <p>Al guardar la evidencia, automaticamente sera estampada en blockchain</p> */}

                  {loading ? <ProgressBar animated now={progress} /> : null}

                  {/* <div className={`${styles["custom-file-button"]} input-group mb-3`}>
                    <label className="input-group-text" htmlFor="ots">
                      Archivo OTS
                    </label>
                    <input type="file" accept=".ots" className="form-control" id="ots" onChange={(e) => setFieldValue("ots", e.target.files[0])} />
                  </div> */}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-around d-flex gap-2">
              <button type="submit" className="btn btn-primary">
                Guardar
              </button>
              {edit ? (
                <button type="button" className="btn btn-outline-danger">
                  Eliminar
                </button>
              ) : null}
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateEvidence;
