import React from 'react';
import clockIcon from '../../../Assets/ic_timer.svg';
import './request-sent.css'

export const RequestSentButton = () =>{


  return (
    <button className="request-sent">
      <span className="request-sent-text">Solicitud enviada</span>
      <img src={clockIcon} />
    </button>
  )
}
