import { useState } from "react";
import { useMutation } from "@apollo/client";
import { CREATE_BATCH } from "../../../graphql/trace/getQrBatches";
import Verification from "./Verification";

function FormItem(props) {
  const {
    id,
    title, //Va en el header
    subtitle, //Va en el header grisado
    icon, // iconito a la izquierda de la tarjeta.
    heading, //Heading del primer item de body
    location, //bajada del primer item del body, va grisado.
    outputIcon, //outputIconn del producto. H: , W:
    by, //Empresa que realiza el proceso.
    logoBy, // LogoBy de la Empresa que realiza el proceso. Opcional
    inputs, //Que se usó para llegar al producto final.
    evidence,
    handleInputChange,
    data,
  } = props;

  // const [batchNumber, setBatchNumber] = useState();
  // const [loteSemilla, setLoteSemilla] = useState();
  // const [calibre, setCalibre] = useState();
  // const [variedad, setVariedad] = useState();
  // const [volume, setVolume] = useState();
  // const [unit, setUnit] = useState();

  // const [createBatch] = useMutation(CREATE_BATCH);

  // const searchParams = useSearchParams();
  // const qrId = searchParams.get("qr");

  // const handleSubmitBatch = async () => {
  //   try {
  //     if (!!batch) {
  //       const data = {
  //         batch_number: batchNumber,
  //         quantity: +volume,
  //         unit: unit,
  //         lote_semilla: loteSemilla,
  //         calibre: calibre,
  //         variedad: variedad,
  //       };
  //       await editBatch({
  //         variables: {
  //           id: batch?.id,
  //           data: data,
  //         },
  //       });
  //       refetch();
  //       alert("El batch se editó correctamente");
  //     } else {
  //       const data = {
  //         batch_number: batchNumber,
  //         quantity: +volume,
  //         unit: unit,
  //         lote_semilla: loteSemilla,
  //         calibre: calibre,
  //         variedad: variedad,
  //         qr_id: {
  //           id: qrId,
  //         },
  //         stage_id: {
  //           id: id,
  //         },
  //       };
  //       await createBatch({
  //         variables: {
  //           data: data,
  //         },
  //       });
  //       refetch();
  //       alert("El batch se creó correctamente");
  //     }
  //   } catch (error) {
  //     console.error("There was a problem with the fetch operation:", error);
  //   }
  // };

  return (
    <div className="row">
      <div className="col-md-7">
        <div className="card  my-3">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between p-1">
              <div className="flex-fill">
                <p>Definir Lote: {title}</p>
                <div className="d-flex mb-3">
                  <div className="input-group">
                    <span className="input-group-text" id="inputGroup-sizing-default">
                      #
                    </span>
                    <input value={data.batchNumber} className="form-control" name="batchNumber" onChange={(e) => handleInputChange(e, id)} />
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="input-group me-3">
                    <span className="input-group-text" id="inputGroup-sizing-default">
                      Cantidad
                    </span>
                    <input value={data.volume} type="number" className="form-control" name="volume" onChange={(e) => handleInputChange(e, id)} />
                  </div>
                  <div className="input-group ">
                    <span className="input-group-text" id="inputGroup-sizing-default">
                      Unidad
                    </span>
                    <input value={data.unit} type="text" className="form-control" name="unit" onChange={(e) => handleInputChange(e, id)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-5">
        <div className="card border-light shadow my-3">
          <div className="card-header bg-transparent border-ligh">
            <p className="mb-0">
              {title} <small className="text-muted">{data[id] && data[id].batchNumber}</small>
            </p>
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between p-1">
              {outputIcon && (
                <img src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${outputIcon}`} className="m-2" alt="" width="60" height="60" />
              )}
              <div className="flex-fill">
                <p className="card-title mb-0">
                  {data[id] && data[id].volume && data[id].unit ? `${data[id].volume} ${data[id].unit} de ${heading}` : heading}
                </p>
                <p className="card-subtitle mb-2 text-muted">
                  <small>{location}</small>
                </p>
              </div>
            </div>

            {inputs && (
              <div className="d-flex align-items-center justify-content-between p-1">
                <div className="flex-fill">
                  <p className="card-title text-muted mb-0">
                    <small>Hecho con</small>
                  </p>
                  <p className="card-subtitle fw-light mb-2">{inputs}</p>
                </div>
              </div>
            )}
            {by && (
              <div className="d-flex align-items-center justify-content-between p-1">
                {logoBy && <img src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${logoBy}`} className="m-2" alt="" width="32" height="32" />}
                <div className="flex-fill">
                  <p className="card-subtitle text-muted mb-0">
                    <small>Hecho por</small>
                  </p>
                  <p className="card-title mb-2">{by}</p>
                </div>
              </div>
            )}
            {evidence && <Verification id={id} data={evidence} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormItem;
