// var parse = require("html-react-parser");

function Proof(props) {
  const { pid, data, className } = props;

  if (data.link) {
    return (
      <a
        href={data.link}
        target="_blank"
        className={className + " text-decoration-none text-reset d-flex align-items-center justify-content-between py-1 border-bottom"}
        rel="noreferrer"
      >
        {data.icon && (
          <img src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${data.icon.id}`} className="m-2" alt={data.title} width="32" height="32" />
        )}

        <div className="mb-0 ms-1 w-100">
          <p className="card-title mb-0">{data.title}</p>
          <div className="card-subtitle mb-0 text-muted">
            <small>{data.subtitle}</small>
          </div>
        </div>

        <i className="bi bi-arrow-up-right-square ml-auto p-1"></i>
      </a>
    );
  }

  return (
    <li
      className={className + " d-flex align-items-center justify-content-between py-1 border-bottom"}
      role="button"
      data-bs-toggle="modal"
      data-bs-target={"#popover_" + pid}
    >
      {data.icon && (
        <img src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${data.icon.id}`} className="m-2" alt={data.title} width="32" height="32" />
      )}

      <div className="mb-0 ms-1 w-100">
        <p className="card-title mb-0">{data.title}</p>
        <div className="card-subtitle mb-0 text-muted">
          <small>{data.subtitle}</small>
        </div>
      </div>

      <i className="bi bi-arrow-up-right-square ml-auto p-1"></i>

      <div
        className="modal fade"
        id={"popover_" + pid}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title fontOne textDark w-100" id="staticBackdropLabel">
                {data.title}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {data.logo && (
                <img src={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${data.logo.id}`} className="mx-auto d-block w-50" alt={data.title} />
              )}
              {/* <div className="mt-3">{data.description ? parse(data.description) : null}</div> */}
              {data.ots && (
                <div className="hint text-muted fw-light mt-4">
                  Todos los documentos de respaldo se registran en blockchain para garantizar la fecha de su escritura. Para verificar el presente
                  documento,
                  <a href={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${data.ots.id}`} download>
                    {" "}
                    descargue el archivo OTS
                  </a>
                  ,&nbsp;
                  <a href={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${data.evidence.id}`} download>
                    descargue tambíen la evidencia
                  </a>{" "}
                  y dirijase a{" "}
                  <a href="https://opentimestamps.org" target="_blank" rel="noreferrer">
                    Open Timestamps
                  </a>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal">
                Cerrar
              </button>
              {data.evidence && (
                <a
                  type="button"
                  className="btn btn-primary"
                  role="button"
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.REACT_APP_PUBLIC_SERVER_ASSETS}/${data.evidence.id}`}
                  download
                >
                  Ver Evidencia
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Proof;
