import favoriteIcon from '../../Assets/favorite.svg';
import React, { useEffect, useRef, useState } from 'react';
import './request-producer-button.css';
import { axiosCircuitExporter } from '../../config/axios';
import Swal from 'sweetalert2';
import { RequestSentButton } from '../buttons-request-ton/request-sent/request-sent';
import { SpecifiedTonButton } from '../buttons-request-ton/specified-ton/specified-ton-button';
import { InputSpecifiedTon } from '../buttons-request-ton/input-specified-ton/input-specified-ton';
import { useSelector, useDispatch } from 'react-redux';
import { SAVE_TOTE } from '../../types';
import { updateOneProducer } from '../../actions/actionCreators';

const RequestProducerButton = ({
  index,
  tonsStatus,
  idHash,
  id_hashes,
  producerTax,
  requestProducer,
  setRequestProducer,
  tonRequestInput,
  setTonRequestInput,
  max,
  maxInitial,
  ton,
  farm,
  id_farm,
  cropName,
  growerName,
  growerTax,
  licenseCompanyTax,
  subLicenseCompanyTax,
  specificTon,
  setSpecificTon,
  paramsToFilter,
  campaignSelected
}) => {
  const externalRef = useRef();
  const [onFocusInput, setOnfocusInput] = useState(false);
  const [showRequestSentButton, setShowRequestSentButton] = useState(false);
  const [savedTon, setSavedTon] = useState(false);
  const requestProducerClick = () =>
    setTimeout(async () => {
      await createTonWithRequestStatus();
    }, 500);
  const user = useSelector((state) => {
    return state.token.user;
  });

  const paginationOptions = useSelector((state) => {
    return state.producers.paginationOptions;
  });
  const dispatch = useDispatch();
  const specificTonClick = () => setSpecificTon((state) => !state);

  const showRequestSent = () => {
    setShowRequestSentButton(true);
    setTimeout(() => {
      setShowRequestSentButton(false);
    }, 3000);
  };

  useEffect(() => {
    if (tonsStatus === 'REQUESTED') {
      setRequestProducer(true);
      setShowRequestSentButton(false);
    }
    if (tonsStatus === 'SPECIFIED') {
      setRequestProducer(true);
      setShowRequestSentButton(false);
      //-------
      setTonRequestInput(ton);
      setSpecificTon(false);
      setSavedTon(true);
    }
  }, [tonsStatus, ton]);

  const handlerBlur = async (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    const valueNumeric = parseFloat(value);
    setOnfocusInput(false);
    // check if valueNumeric is not a number
    if (isNaN(valueNumeric)) {
      return;
    }
    try {
      if (parseFloat(value) === 0) {
        setTonRequestInput('');
        setSpecificTon(true);
      }
      const response = await axiosCircuitExporter.put('/v2/tons', {
        tons: valueNumeric,
        id_hashes,
      });
      if (response.status === 200) {
        const {
          search = '',
          filters = null,
          querySearch = '',
          farm = '',
          province = '',
          departments = [],
        } = paramsToFilter;

        let parsedFilters = '';
        if (filters !== null) {
          parsedFilters = Object.entries(filters)
            .map((filter) => `${filter[0]}=${filter[1]}`)
            .join(',');
        }

        let parsedDepartments = '';
        if (Boolean(departments.length)) {
          parsedDepartments = departments.join(',');
        }

        const options = {
          method: 'GET',
          params: {
            page: 1,
            numPerPage: 10,
            search,
            filters: parsedFilters,
            querySearch,
            farm,
            province,
            departments: parsedDepartments,
            licenseCampaign: campaignSelected
          },
        };

        const { data } = await axiosCircuitExporter(
          `/v2/data-bi/identifier/${user?.identifier}`,
          options
        );

        const { metaData } = data;
        dispatch(saveToTes(metaData));
      }

      setSavedTon(true);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al especificar toneladas.',
        confirmButtonColor: '#96C24D',
      });
    }
  };
  const onKeyPressHandler = async (e) => {
    const { keyCode } = e;
    if (keyCode === 13) await editTon();
  };

  const saveToTes = (totes) => ({
    type: SAVE_TOTE,
    payload: totes,
  });

  const changeInput = (e) => {
    const value = e.target.value.replace(/[^0-9.]/g, '');
    if (parseFloat(value) !== 0) {
      if (!parseFloat(value)) {
        setTonRequestInput('');
        return;
      }
    }
    const valueNumeric = parseFloat(value);
    if (valueNumeric < 0) {
      setTonRequestInput('');
    } else if (valueNumeric > parseFloat(maxInitial)) {
      setTonRequestInput(maxInitial);
    } else {
      setTonRequestInput(value);
    }
  };

  const createTonWithRequestStatus = async () => {
    const tonCreate = {
      producerTax,
      exporterId: user.id,
      growerName: growerName,
      growerTax: growerTax,
      cropName: cropName,
      farm: farm,
      id_farm: id_farm,
      licenseCompanyTax: licenseCompanyTax,
      subLicenseCompanyTax: subLicenseCompanyTax
        ? subLicenseCompanyTax
        : undefined,
      id_hashes,
    };
    try {
      const { data: producerWithTonCreated } = await axiosCircuitExporter.post(
        'v2/tons',
        tonCreate
      );
      setRequestProducer(true);
      showRequestSent();

      const { numPerPage } = paginationOptions;
      const pageOfProducer = Math.ceil((index + 1) / numPerPage);

      const {
        search = '',
        filters = null,
        querySearch = '',
        farm = '',
        province = '',
        departments = [],
      } = paramsToFilter;

      let parsedFilters = '';
      if (filters !== null) {
        parsedFilters = Object.entries(filters)
          .map((filter) => `${filter[0]}=${filter[1]}`)
          .join(',');
      }
      let parsedDepartments = '';
      if (Boolean(departments.length)) {
        parsedDepartments = departments.join(',');
      }

      const options = {
        method: 'GET',
        params: {
          page: pageOfProducer,
          numPerPage,
          search,
          filters: parsedFilters,
          querySearch,
          farm,
          province,
          departments: parsedDepartments,
          licenseCampaign: campaignSelected
        },
      };
      const { data } = await axiosCircuitExporter(
        `/v2/data-bi/identifier/${user?.identifier}`,
        options
      );

      const { data: producerList } = data;

      const updatedProducer = producerList.find(
        (producer) =>
          String(producer.id_hash) === String(producerWithTonCreated.idHash)
      );
      if (updatedProducer) {
        dispatch(updateOneProducer(updatedProducer));
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al solicitar toneladas.',
        confirmButtonColor: '#96C24D',
      });
    }
  };

  const editTon = async () => {
    const valueNumeric = parseFloat(tonRequestInput);
    if (isNaN(valueNumeric)) {
      return;
    }
    if (parseFloat(tonRequestInput) === 0) {
      setTonRequestInput('');
      setSpecificTon(true);
    }
    try {
      const response = await axiosCircuitExporter.put('/v2/tons', {
        tons: parseFloat(tonRequestInput),
        id_hashes,
      });
      if (response.status === 200) {
        const {
          search = '',
          filters = null,
          querySearch = '',
          farm = '',
          province = '',
          departments = [],
        } = paramsToFilter;

        let parsedFilters = '';
        if (filters !== null) {
          parsedFilters = Object.entries(filters)
            .map((filter) => `${filter[0]}=${filter[1]}`)
            .join(',');
        }

        let parsedDepartments = '';
        if (Boolean(departments.length)) {
          parsedDepartments = departments.join(',');
        }

        const options = {
          method: 'GET',
          params: {
            page: 1,
            numPerPage: 10,
            search,
            filters: parsedFilters,
            querySearch,
            farm,
            province,
            departments: parsedDepartments,
            licenseCampaign: campaignSelected,
          },
        };
        const { data } = await axiosCircuitExporter(
          `/v2/data-bi/identifier/${user?.identifier}`,
          options
        );

        const { metaData } = data;
        dispatch(saveToTes(metaData));
      }
      setOnfocusInput(false);

      removeBlur();
      setSavedTon(true);
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ha ocurrido un error al editar toneladas.',
        confirmButtonColor: '#96C24D',
      });
    }
  };
  const removeBlur = () => externalRef?.current?.blur();
  return (
    <div className='d-flex'>
      {!requestProducer ? (
        <button className='requestProducer' onClick={requestProducerClick}>
          <span className='requestText'>Solicitar Campo</span>
          <img alt='favorite-icon' src={favoriteIcon} />
        </button>
      ) : showRequestSentButton ? (
        <RequestSentButton />
      ) : specificTon ? (
        <SpecifiedTonButton onClick={specificTonClick} />
      ) : (
        <InputSpecifiedTon
          handlerBlur={handlerBlur}
          tonRequestInput={tonRequestInput}
          changeInput={changeInput}
          savedTon={savedTon}
          editTon={editTon}
          onFocusInput={onFocusInput}
          setOnfocusInput={setOnfocusInput}
          onKeyPressEnter={onKeyPressHandler}
          externalRef={externalRef}
        />
      )}
    </div>
  );
};

export default RequestProducerButton;
