import React from "react";
import clockIcon from "../../../Assets/ic_timer.svg";
import "./input-specified-ton.css";

export const InputSpecifiedTon = ({
  handlerBlur,
  tonRequestInput,
  changeInput,
  editTon,
  savedTon,
  onFocusInput,
  setOnfocusInput,
  onKeyPressEnter,
  externalRef,
}) => {
  const focusHandler = (e) => setOnfocusInput(true);
  return (
    <div className="input-specified-ton ">
      <div className="div-row">
        <input
          ref={externalRef}
          className="input-ton"
          onChange={changeInput}
          onBlur={handlerBlur}
          value={tonRequestInput}
          onFocus={focusHandler}
          onKeyUpCapture={onKeyPressEnter}
        />
        {savedTon && <span className="span-ton">tn</span>}
      </div>

      <div className="div-row">
        {savedTon && <span className="span-text-requested">Reclamadas</span>}
        <button
          className={!savedTon ? "edit-button save-edit-only" : "edit-button"}
          onClick={editTon}
        >
          {onFocusInput ? "Guardar" : "Editar"}
        </button>
      </div>
    </div>
  );
};
