import React, { useCallback, useEffect, useState } from 'react';
import RequestProducerButton from '../request-producer/request-producer-button';
import ChipSDP from '../chips/chipsSDP/chip-s-d-p';
import ChipDDSS from '../chips/chipsDDSS/chip-d-d-s-s';
import ChipCU from '../chips/chipsCU/chip-cu';
import ButtonDownload from '../buttons-download/button-download';
import Swal from 'sweetalert2';
import parseNumber from "../../utils/parse-number";

export const ProductItem = ({
  index,
  producer,
  tonsStatus,
  ton,
  idHash,
  paramsToFilter,
  department,
  province,
  campaignSelected
}) => {
  const {
    _2bsvs_evidence = false,
    has_2bsvs_evidence = false,
    _2bsvs_agreement = false,
    id_table,
    farm_available_quantity,
    grower_name = '',
    grower_cuit = '',
    crop_name = '',
    farm = '',
    company_cuit = '',
    subcompany_cuit = '',
    verification_activity = '',
    verification_evidence = '',
    ddss_evidence = '',
    has_ddss_evidence = false,
    id_hashes,
    license_campaign
  } = producer;
  const [specificTon, setSpecificTon] = useState(true);
  const [requestProducer, setRequestProducer] = useState(false);
  const [tonRequestInput, setTonRequestInput] = useState(null);
  const [availableItem, setAvailableItem] = useState(0);

  useEffect(() => {
    if (
      farm_available_quantity &&
      tonRequestInput !== null &&
      parseFloat(farm_available_quantity) >= parseFloat(tonRequestInput)
    ) {
      const subtraction =
        parseFloat(farm_available_quantity) - parseFloat(tonRequestInput);

      setAvailableItem(Math.round(subtraction * 100) / 100);
    } else {
      setAvailableItem(farm_available_quantity);
    }
  }, [tonRequestInput]);
  const displayAlertRequestTons = () => {
    Swal.fire({
      icon: 'error',
      title: ' Acción inválida',
      text: 'Debes solicitar el campo para poder descargar su documentación',
      confirmButtonColor: '#96C24D',
    });
  };

  const renderLocation = useCallback(() => {
    let str = '';
    const boolDepartment = Boolean(department);
    const boolProvince = Boolean(province);

    if (!boolDepartment && !boolProvince) {
      return null;
    }

    if (boolDepartment) {
      str += department;
    }

    if (boolProvince) {
      str += `${boolDepartment ? ', ' : ''}${province}`;
    }

    return <span className='producerInfoSubtitle mt-1'>{str}</span>;
  }, [department, province]);

  return (
    <div className='producerItem'>
      <div className='producerInfo'>
        <div className='d-flex flex-column'>
          <h3 className='subtitle'>{farm}</h3>
          <span className='producerInfoBusinessName'>{grower_name}</span>
          <span className='producerInfoSubtitle'>CUIT {grower_cuit}</span>
          {renderLocation()}
        </div>
        <div className='d-flex flex-column'>
          <h3 className='subtitle'>{parseNumber(farm_available_quantity)} tn</h3>
          <span className='producerInfoSubtitle'>Producidas</span>
        </div>
        <div className='d-flex flex-column'>
          <h3 className='subtitle'>{parseNumber(availableItem)} tn</h3>
          <span className='producerInfoSubtitle'>Disponibles</span>
        </div>
        <RequestProducerButton
          index={index}
          tableId={id_table}
          idHash={idHash}
          id_hashes={id_hashes}
          producerTax={company_cuit}
          growerName={grower_name}
          growerTax={grower_cuit}
          cropName={crop_name}
          farm={farm}
          licenseCompanyTax={company_cuit}
          subLicenseCompanyTax={subcompany_cuit}
          tonsStatus={tonsStatus}
          requestProducer={requestProducer}
          setRequestProducer={setRequestProducer}
          tonRequestInput={tonRequestInput}
          setTonRequestInput={setTonRequestInput}
          max={availableItem}
          maxInitial={farm_available_quantity}
          ton={ton}
          specificTon={specificTon}
          setSpecificTon={setSpecificTon}
          paramsToFilter={paramsToFilter}
          campaignSelected={license_campaign}
        />
      </div>
      <div className='divider'></div>
      <div className='producerFooter mt-3'>
        <div className='d-flex gap-3 item'>
          <ChipSDP
            hasEvidence={Boolean(has_2bsvs_evidence)}
            evidence={_2bsvs_evidence}
            existValueTon={requestProducer || tonsStatus}
            displayAlertRequestTons={displayAlertRequestTons}
          />
          <ChipDDSS
            hasAgreement={Boolean(has_ddss_evidence)}
            agreement={ddss_evidence}
            existValueTon={requestProducer || tonsStatus}
            displayAlertRequestTons={displayAlertRequestTons}
          />
        </div>

        <ChipCU
          validationCU={verification_activity}
          existValueTon={requestProducer || tonsStatus}
        />
        <div className='d-flex item downloadReportCointainer'>
          <ButtonDownload
            idHash={idHash}
            cropName={crop_name}
            farm={farm}
            producer={producer}
            licenseCompanyTax={company_cuit}
            subLicenseCompanyTax={subcompany_cuit}
            agreement2bsvs={_2bsvs_agreement}
            existValueTon={requestProducer || tonsStatus}
            tonRequestInput={tonRequestInput || 0}
            agreementDDSS={ddss_evidence}
            validationCU={verification_evidence}
          />
        </div>
      </div>
    </div>
  );
};
