import React, { forwardRef, useImperativeHandle, useState } from "react";
import closeIcon from "../../Assets/close.svg";
import trashIcon from "../../Assets/trash.svg";
import exceljs from "exceljs";

import "./RightSidebar.css";

const RightSidebar = forwardRef(
  ({ handleShowSidebar, sidebarTo, handleSubmit }, ref) => {
    const [noFilters, setnoFilters] = useState(true);
    const [noRequested, setnoRequested] = useState(false);
    const [allRequested, setallRequested] = useState(false);

    // Requests
    const [requestEntered, setrequestEntered] = useState(true);
    const [specifiedTons, setspecifiedTons] = useState(true);

    //Documents
    const [sdpPublished, setsdpPublished] = useState(true);
    const [ddssPublished, setddssPublished] = useState(true);
    const [cuPublished, setcuPublished] = useState(true);

    const [cuitsList, setcuitsList] = useState("");
    const [spreadsheetFile, setspreadsheetFile] = useState(null);

    useImperativeHandle(ref, () => ({
      handleResetValues() {
        handleSubmit({
          toFilter: {
            SDP: false,
            DDSS: false,
            CU: false,
            requested: false,
            specified: false,
          },
          toSearch: "",
          toClear: "resetFilters",
        });
        setrequestEntered(false);
        setspecifiedTons(false);
        setsdpPublished(false);
        setddssPublished(false);
        setcuPublished(false);
        setcuitsList("");
      },

      handleResetFilters() {
        handleSubmit({
          toFilter: {
            noFilters: noFilters,
            noRequested: noRequested,
            allRequested: allRequested,
            SDP: false,
            DDSS: false,
            CU: false,
            requested: false,
            specified: false,
          },
          toSearch: cuitsList,
          toClear: "resetFilters",
        });
        setnoFilters(true);
        setallRequested(false);
        setnoRequested(false);
        setcuPublished(true);
        setddssPublished(true);
        setsdpPublished(true);
        setrequestEntered(true);
        setspecifiedTons(true);
      },

      handleResetSearch() {
        handleSubmit({
          toFilter: {
            noFilters: noFilters,
            noRequested: noRequested,
            allRequested: allRequested,
            SDP: sdpPublished,
            DDSS: ddssPublished,
            CU: cuPublished,
            requested: requestEntered,
            specified: specifiedTons,
          },
          toSearch: "",
          toClear: "resetSearch",
        });
        setcuitsList("");
      },

      clearCuits() {
        setcuitsList("");
      },

      handleResetOneStatus(status) {
        switch (status) {
          case "SDP":
            setsdpPublished(false);
            handleSubmit({
              toFilter: {
                SDP: false,
                DDSS: ddssPublished,
                CU: cuPublished,
                requested: requestEntered,
                specified: specifiedTons,
              },
              toSearch: cuitsList,
              toClear: "resetSDP",
            });
            break;
          case "DDSS":
            setddssPublished(false);
            handleSubmit({
              toFilter: {
                SDP: sdpPublished,
                DDSS: false,
                CU: cuPublished,
                requested: requestEntered,
                specified: specifiedTons,
              },
              toSearch: cuitsList,
              toClear: "resetDDSS",
            });
            break;
          case "CU":
            setcuPublished(false);
            handleSubmit({
              toFilter: {
                SDP: sdpPublished,
                DDSS: ddssPublished,
                CU: false,
                requested: requestEntered,
                specified: specifiedTons,
              },
              toSearch: cuitsList,
              toClear: "resetCU",
            });
            break;
          case "CUIT":
            setcuitsList("");
            handleSubmit({
              toFilter: {
                SDP: sdpPublished,
                DDSS: ddssPublished,
                CU: cuPublished,
                requested: requestEntered,
                specified: specifiedTons,
              },
              toSearch: "",
              toClear: "resetCUITS",
            });
            break;
          case "requested":
            setrequestEntered(false);
            handleSubmit({
              toFilter: {
                SDP: sdpPublished,
                DDSS: ddssPublished,
                CU: cuPublished,
                requested: false,
                specified: specifiedTons,
              },
              toSearch: cuitsList,
              toClear: "resetRequested",
            });
            break;
          case "specified":
            setspecifiedTons(false);
            handleSubmit({
              toFilter: {
                SDP: sdpPublished,
                DDSS: ddssPublished,
                CU: cuPublished,
                requested: requestEntered,
                specified: false,
              },
              toSearch: cuitsList,
              toClear: "resetRequested",
            });
            break;
          default:
            break;
        }
      },
    }));

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileAsBinaryString = e.target.result;
        // read from a stream
        const workbook = new exceljs.Workbook();
        workbook.xlsx.load(fileAsBinaryString).then(function (document) {
          const worksheet = document.getWorksheet(1);
          const rows = worksheet.getColumn(1).values;
          let cuits = rows.slice(2, rows.length);
          cuits = cuits.join(",");
          // add comma to prevCuits if it's not empty.
          if (cuitsList !== "") {
            setcuitsList((prevCuits) => `${prevCuits}, ${cuits}`);
          } else {
            setcuitsList(cuits);
          }
        });
      };

      setspreadsheetFile(file);
      reader.readAsBinaryString(file);
    };

    const handleChangeCuits = (e) => {
      //allow only numbers, commas, semicolons line breaks and spaces.
      const cuits = e.target.value.replace(/[^0-9,;\n\s]/g, "");
      setcuitsList(cuits);
    };

    const changeRadius = (e) => {
      if (e.target.value === "noFilters") {
        setnoFilters(true);
        setallRequested(false);
        setnoRequested(false);
        setcuPublished(true);
        setddssPublished(true);
        setsdpPublished(true);
        setrequestEntered(true);
        setspecifiedTons(true);
      }
      if (e.target.value === "noRequested") {
        setnoFilters(false);
        setallRequested(false);
        setnoRequested(true);
        setcuPublished(false);
        setddssPublished(false);
        setsdpPublished(false);
        setrequestEntered(false);
        setspecifiedTons(false);
      }
      if (e.target.value === "allRequested") {
        setnoFilters(false);
        setallRequested(true);
        setnoRequested(false);
        setcuPublished(true);
        setddssPublished(true);
        setsdpPublished(true);
        setrequestEntered(true);
        setspecifiedTons(true);
      }
    };

    return (
      <>
        <div
          id="sidebar_overlay"
          className="sidebar_overlay"
          onClick={() => {
            if (sidebarTo === "toFilter") {
              handleShowSidebar("toFilter");
            } else {
              handleShowSidebar("toSearch");
            }
          }}
        ></div>
        {sidebarTo === "toFilter" ? (
          <div id="sidebar_content" className="sidebar_content">
            <div className="sidebar_header">
              <h1>Filtrar</h1>
              <button
                className="close_button"
                onClick={() => handleShowSidebar("toFilter")}
              >
                <img src={closeIcon} alt="closeIcon" />
              </button>
            </div>
            <form className="form-filter">
              <div className="sidebar_body">
                <div className="sidebar_body_section">
                  <div className="sidebar_body_section_title">
                    <h2>Solicitudes de campos</h2>
                  </div>
                  <div className="sidebar_body_item">
                    <div>
                      <p className="item_label">Mostrar todos los campos</p>
                    </div>
                    <label class="customInputContainer">
                      <input
                        type="radio"
                        name="radio"
                        onChange={changeRadius}
                        value="noFilters"
                        checked={noFilters}
                      />
                      <span class="radioCheckMark"></span>
                    </label>
                  </div>
                  <div className="sidebar_body_item">
                    <div>
                      <p className="item_label">
                        Mostrar sólo los campos sin solicitar
                      </p>
                    </div>
                    <label class="customInputContainer">
                      <input
                        type="radio"
                        name="radio"
                        onChange={changeRadius}
                        value="noRequested"
                        checked={noRequested}
                      />
                      <span class="radioCheckMark"></span>
                    </label>
                  </div>
                  <div className="sidebar_body_item">
                    <div>
                      <p className="item_label">
                        Mostrar sólo los campos solicitados
                      </p>
                    </div>
                    <label class="customInputContainer">
                      <input
                        type="radio"
                        name="radio"
                        value="allRequested"
                        onChange={changeRadius}
                        checked={allRequested}
                      />
                      <span class="radioCheckMark"></span>
                    </label>
                  </div>
                  <div className="section_divider" />
                </div>
                <div className="sidebar_body_section">
                  <div className="sidebar_body_section_title">
                    <h2>Reclamo de toneladas</h2>
                  </div>
                  <div className="sidebar_body_item">
                    <div>
                      <p className="item_label">
                        Mostrar los campos sin toneladas reclamadas
                      </p>
                    </div>
                    <label class="customInputContainer">
                      <input
                        type="checkbox"
                        checked={requestEntered}
                        onChange={() => setrequestEntered(!requestEntered)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div className="sidebar_body_item">
                    <div>
                      <p className="item_label">
                        Mostrar los campos con toneladas reclamadas
                      </p>
                    </div>
                    <label class="customInputContainer">
                      <input
                        type="checkbox"
                        checked={specifiedTons}
                        onChange={() => setspecifiedTons(!specifiedTons)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div className="section_divider" />
                  {(noFilters || noRequested) && (
                    <div className="opacity"></div>
                  )}
                </div>
                <div className="sidebar_body_section">
                  <div className="sidebar_body_section_title">
                    <h2>Documentación publicada</h2>
                  </div>
                  <div className="sidebar_body_item">
                    <div>
                      <p className="item_label">
                        SDP Sustentabilidad del Paisaje
                      </p>
                      <span className="item_description">
                        Mostrar los campos que contengan el informe SDP.
                      </span>
                    </div>
                    <label class="customInputContainer">
                      <input
                        type="checkbox"
                        checked={sdpPublished}
                        onChange={() => setsdpPublished(!sdpPublished)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div className="sidebar_body_item">
                    <div>
                      <p className="item_label">
                        DDSS Autodeclaración de Sustentabilidad
                      </p>
                      <span className="item_description">
                        Mostrar los campos con la autodeclaración creada.
                      </span>
                    </div>
                    <label class="customInputContainer">
                      <input
                        type="checkbox"
                        checked={ddssPublished}
                        onChange={() => setddssPublished(!ddssPublished)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div className="sidebar_body_item">
                    <div>
                      <p className="item_label">
                        CU Verificación Control Union
                      </p>
                      <span className="item_description">
                        Mostrar los campos que cuenten con la verificación.
                      </span>
                    </div>
                    <label class="customInputContainer">
                      <input
                        type="checkbox"
                        checked={cuPublished}
                        onChange={() => setcuPublished(!cuPublished)}
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div className="section_divider" />
                  {(noFilters || noRequested) && (
                    <div className="opacity"></div>
                  )}
                </div>
              </div>
            </form>
            <div className="d-flex sidebar_actions justify-content-end">
              <button disabled className="d-none"></button>
              <button
                type="button"
                onClick={() =>
                  handleSubmit({
                    toFilter: {
                      noFilters: noFilters,
                      noRequested: noRequested,
                      allRequested: allRequested,
                      SDP: sdpPublished,
                      DDSS: ddssPublished,
                      CU: cuPublished,
                      requested: requestEntered,
                      specified: specifiedTons,
                    },
                    toSearch: cuitsList,
                  })
                }
                disabled={allRequested && !requestEntered && !specifiedTons}
              >
                Filtrar
              </button>
            </div>
          </div>
        ) : (
          <div id="sidebar_content" className="sidebar_content">
            <div className="sidebar_header">
              <h1>Buscar</h1>
              <button
                className="close_button"
                onClick={() => handleShowSidebar("toSearch")}
              >
                <img src={closeIcon} alt="closeIcon" />
              </button>
            </div>
            <div className="sidebar_body">
              <div className="sidebar_body_section">
                <div className="sidebar_body_item mt-0 flex-column">
                  <div>
                    <p className="item_label">Solicitud ingresada</p>
                    <span className="item_description">
                      Ingresa uno o más CUIT de empresas a consultar separados
                      por un espacio, una coma o un punto y coma.
                    </span>
                  </div>
                  <div>
                    <textarea
                      placeholder="Ingresa uno o varios códigos"
                      value={cuitsList}
                      onChange={(e) => handleChangeCuits(e)}
                    />
                  </div>
                </div>
                <div className="section_divider" />
              </div>
              <div className="sidebar_body_section">
                <div className="sidebar_body_item mt-0 flex-column">
                  <div className="w-100">
                    <p className="item_label">Buscar por planilla</p>
                    <span className="item_description">
                      Sube un archivo XLS que contenga los CUIT de las empresas
                      a consultar.
                    </span>
                  </div>
                  <div className="w-100 mt-3 mb-4">
                    {spreadsheetFile === null ? (
                      <label htmlFor="spreadsheet-button-file">
                        <span className="button-file">Subir archivo</span>
                        <input
                          id="spreadsheet-button-file"
                          type="file"
                          className="d-none"
                          accept=".xlsx, .xls, .csv"
                          onChange={handleFileUpload}
                        />
                      </label>
                    ) : (
                      <div className="file-preview-container">
                        <span>{spreadsheetFile?.name}</span>
                        <button
                          className="trash-button"
                          onClick={() => setspreadsheetFile(null)}
                        >
                          <img src={trashIcon} alt="trashIcon" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="section_divider" />
              </div>
            </div>
            <div className="sidebar_actions justify-content-end">
              <button disabled className="d-none"></button>
              <button
                type="button"
                disabled={cuitsList === "" && spreadsheetFile === null}
                onClick={() =>
                  handleSubmit({
                    toFilter: {
                      SDP: sdpPublished,
                      DDSS: ddssPublished,
                      CU: cuPublished,
                      requested: requestEntered,
                      specified: specifiedTons,
                    },
                    toSearch: cuitsList,
                  })
                }
              >
                Buscar
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default RightSidebar;
