import React, { useState } from "react";
import arrowLeft from "../../../Assets/ArrowLeft.svg";
import { Typography, Box, Button } from "@mui/material";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useLazyQuery, useQuery } from "@apollo/client";
import { CREATE_QR, GET_QRS, GET_QR_BY_NAME } from "../../../graphql/getQrs";
import { green } from "@mui/material/colors";
import { GET_STAGES } from "../../../graphql/trace/getStages";
import Timeline from "../Trace/Timeline";
import FormItem from "../Trace/FormItem";
import { useMutation } from "@apollo/client";
import { arrayJoin } from "../../../utils/arrayJoin";
import FormItemGran from "../Trace/FormItemGran";

const CreateQR = () => {
  const { productId } = useParams();
  const history = useHistory();

  const [createQR] = useMutation(CREATE_QR);

  const { data: { product_by_id } = {} } = useQuery(GET_QRS, {
    variables: {
      id: productId,
    },
  });

  const { data: { product_by_id: { stages } = {} } = {} } = useQuery(GET_STAGES, {
    variables: {
      id: productId,
    },
  });

  const [getQR, { data }] = useLazyQuery(GET_QR_BY_NAME);

  const strings = stages ? stages.map((stage) => stage.title) : [];

  const [formData, setFormData] = useState([]);
  const [qrName, setQrName] = useState("");

  const handleInputChange = (e, formId) => {
    const { value, name } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [formId]: {
        ...prevState[formId],
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    const { data: response } = await getQR({ variables: { id: productId, name: qrName } });
    if (response && response?.product_by_id?.qrs?.length <= 0) {
      const payload = {
        product_id: {
          id: productId,
        },
        name: qrName,
        batches: Object.keys(formData).map((key) => {
          if (productId === "8a688a7a-c42f-4d22-9a19-de87d356dfe6") {
            return {
              stage_id: { id: key },
              batch_number: formData[key].batchNumber,
              quantity: +formData[key].volume,
              unit: formData[key].unit,
              lote_semilla: formData[key].loteSemilla,
              calibre: formData[key].calibre,
              variedad: formData[key].variedad,
            };
          } else {
            return {
              stage_id: { id: key },
              batch_number: formData[key].batchNumber,
              quantity: +formData[key].volume,
              unit: formData[key].unit,
            };
          }
        }),
      };
      e.preventDefault();
      await createQR({
        variables: {
          data: payload,
        },
      });
      history.goBack();
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        pt: 4,
        px: 7,
      }}
    >
      <Tooltip title="Regresar" sx={{ mr: 2, mb: 1, pl: 0 }}>
        <IconButton>
          <Box component="img" src={arrowLeft} alt="edit-icon" width={24} onClick={() => history.goBack()} />
        </IconButton>
      </Tooltip>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ maxWidth: "700px" }}>
          <Typography component="h1" variant="h6" sx={{ fontSize: 24, fontWeight: 400, mb: 1 }}>
            Trazabilidad / {product_by_id && ` ${product_by_id.name}`} /
            <Typography component="span" variant="h6" sx={{ fontSize: 24, fontWeight: 700, mb: 1 }}>
              {" "}
              Crear nuevo QR
            </Typography>
          </Typography>
          <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 400 }}>
            {`Agrega las características y los logros sostenibles a lo largo del ciclo de vida del producto, desde su ${
              strings[0]
            } hasta su ${arrayJoin(strings)}.`}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button variant="contained" sx={{ backgroundColor: green[500], "&:hover": { bgcolor: green[600] } }} onClick={handleSubmit}>
            Crear nuevo QR
          </Button>
        </Box>
      </Box>

      <div className="mt-5">
        <Timeline>
          <div className="row">
            <div className="col-md-7">
              <div className="card  my-3">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between p-1">
                    <div className="flex-fill">
                      <p>Definir ID</p>
                      <div className="input-group mb-1">
                        <span className="input-group-text" id="inputGroup-sizing-default">
                          ID
                        </span>
                        <input value={qrName} className="form-control" name="batchNumber" onChange={(e) => setQrName(e.target.value)} />
                      </div>
                      {data && data?.product_by_id?.qrs?.length > 0 ? (
                        <Typography component="span" variant="h6" sx={{ fontSize: 16, fontWeight: 400, color: "#dc3545", mb: 3 }}>
                          Este ID ya existe.
                        </Typography>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {stages
            ? stages.map((stage, index) => {
                if (productId === "8a688a7a-c42f-4d22-9a19-de87d356dfe6") {
                  return (
                    <FormItemGran
                      id={stage.id}
                      key={index}
                      type={stage.type}
                      title={stage.title}
                      subtitle={stage.subtitle}
                      icon={stage.icon}
                      heading={stage.output}
                      location={stage.location}
                      outputIcon={stage.output_icon?.id}
                      by={stage.by}
                      logoBy={stage.logo_by?.id}
                      inputs={stage.inputs}
                      evidences={stage.evidences}
                      handleInputChange={handleInputChange}
                      data={formData}
                    />
                  );
                } else {
                  return (
                    <FormItem
                      id={stage.id}
                      key={index}
                      type={stage.type}
                      title={stage.title}
                      subtitle={stage.subtitle}
                      icon={stage.icon}
                      heading={stage.output}
                      location={stage.location}
                      outputIcon={stage.output_icon?.id}
                      by={stage.by}
                      logoBy={stage.logo_by?.id}
                      inputs={stage.inputs}
                      evidences={stage.evidences}
                      handleInputChange={handleInputChange}
                      data={formData}
                    />
                  );
                }
              })
            : null}
        </Timeline>
      </div>
    </Box>
  );
};

export default CreateQR;
