import React, { useEffect, useState } from "react";
import arrowLeft from "../../../Assets/ArrowLeft.svg";
import { Typography, Box } from "@mui/material";
import {
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_QRS, GET_QR_BY_NAME, UPDATE_QR } from "../../../graphql/getQrs";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { GET_STAGES } from "../../../graphql/trace/getStages";
import { GET_QR_BATCHES } from "../../../graphql/trace/getQrBatches";
import EditFormItem from "../Trace/EditFormItem";
import Timeline from "../Trace/Timeline";
import EditFormItemGran from "../Trace/EditFormItemGran";

const EditQR = () => {
  const { qrId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const productId = new URLSearchParams(location.search).get("productId");

  const { data: { product_by_id } = {} } = useQuery(GET_QRS, {
    variables: {
      id: productId,
    },
  });
  const mapId = product_by_id?.map.length ? product_by_id?.map[0]?.id : null;

  const { data: { product_by_id: { stages } = {} } = {} } = useQuery(
    GET_STAGES,
    {
      variables: {
        id: productId,
      },
    }
  );

  const { data: { qr_by_id: { batches, name } = {} } = {}, refetch } = useQuery(
    GET_QR_BATCHES,
    {
      variables: {
        id: qrId,
      },
    }
  );

  const [qrName, setQrName] = useState(name);

  const [getQR, { data }] = useLazyQuery(GET_QR_BY_NAME);
  const [updateQR] = useMutation(UPDATE_QR);

  useEffect(() => {
    setQrName(name);
  }, [name]);

  const handleEditName = async () => {
    const { data: response } = await getQR({
      variables: { id: productId, name: qrName },
    });
    if (response && response?.product_by_id?.qrs?.length <= 0) {
      await updateQR({
        variables: {
          id: qrId,
          data: {
            name: qrName,
          },
        },
      });
      alert("El ID se modificó correctamente");
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        pt: 4,
        px: 7,
      }}
    >
      <Tooltip title="Regresar" sx={{ mr: 2, mb: 1, pl: 0 }}>
        <IconButton>
          <Box
            component="img"
            src={arrowLeft}
            alt="edit-icon"
            width={24}
            onClick={() => history.goBack()}
          />
        </IconButton>
      </Tooltip>

      <Typography
        component="h1"
        variant="h6"
        sx={{ fontSize: 24, fontWeight: 400, mb: 1 }}
      >
        Producto /
        <Typography
          component="span"
          variant="h6"
          sx={{ fontSize: 24, fontWeight: 700, mb: 1 }}
        >
          {qrId && ` Editar QR ID ${qrId}`}
        </Typography>
      </Typography>
      <Typography
        component="h1"
        variant="h6"
        sx={{ fontSize: 16, fontWeight: 400, mb: 6 }}
      >
        Edita las características y los logros sostenibles desde su orígen hasta
        su transporte, procesamiento y fabricación.
      </Typography>
      {/* {product_by_id ? <iframe title="edit" src={`${product_by_id.website_url}/form?qr=${qrId}`} width="100%" height="80%" /> : null} */}

      <div className="mt-5">
        <Timeline>
          <div className="row">
            <div className="col-md-7">
              <div className="card  my-3">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between p-1">
                    <div className="flex-fill">
                      <p>Definir ID</p>
                      <div className="input-group mb-1">
                        <span
                          className="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          ID
                        </span>
                        <input
                          value={qrName}
                          className="form-control"
                          name="batchNumber"
                          onChange={(e) => setQrName(e.target.value)}
                        />
                      </div>
                      {data && data?.product_by_id?.qrs?.length > 0 ? (
                        <Typography
                          component="span"
                          variant="h6"
                          sx={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#dc3545",
                            mb: 3,
                          }}
                        >
                          Este ID ya existe.
                        </Typography>
                      ) : null}

                      <div className="mt-2">
                        <button
                          onClick={handleEditName}
                          className="btn btn-primary"
                        >
                          Editar ID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {stages && batches
            ? stages.map((stage, index) => {
                const batch = batches
                  ? batches.find((batch) => batch.stage_id.id === stage.id)
                  : {};

                const batchEvidences = batch?.evidences?.map((ev) => {
                  return {
                    evidence_id: ev,
                  };
                });
                const batchPoints = batch?.points?.map((ev) => {
                  return {
                    evidence_id: ev,
                  };
                });

                const evidences =
                  batch && batch?.evidences?.length > 0
                    ? [...stage.evidences, ...batchEvidences]
                    : stage.evidences;
                if (productId === "8a688a7a-c42f-4d22-9a19-de87d356dfe6") {
                  return (
                    <EditFormItemGran
                      batch={batch}
                      id={stage.id}
                      key={index}
                      type={stage.type}
                      title={stage.title}
                      subtitle={stage.subtitle}
                      icon={stage.icon}
                      heading={stage.output}
                      location={stage.location}
                      outputIcon={stage.output_icon?.id}
                      by={stage.by}
                      logoBy={stage.logo_by?.id}
                      inputs={stage.inputs}
                      evidence={evidences}
                      batchEvidences={batchEvidences}
                      refetch={refetch}
                    />
                  );
                } else {
                  return (
                    <EditFormItem
                      batch={batch}
                      id={stage.id}
                      key={index}
                      type={stage.type}
                      title={stage.title}
                      subtitle={stage.subtitle}
                      icon={stage.icon}
                      heading={stage.output}
                      location={stage.location}
                      outputIcon={stage.output_icon?.id}
                      by={stage.by}
                      logoBy={stage.logo_by?.id}
                      inputs={stage.inputs}
                      evidence={evidences}
                      batchEvidences={batchEvidences}
                      refetch={refetch}
                      batchPoints={batchPoints}
                      mapId={mapId}
                    />
                  );
                }
              })
            : null}
        </Timeline>
      </div>
    </Box>
  );
};

export default EditQR;
