import { SAVE_PRODUCERS, SAVE_MATCHES, UPDATE_ONE_PRODUCER } from '../types';

const initialState = {
  paginationOptions: {
    page: 1,
    numPerPage: 10,
    next: null,
  },
  producers: [],
  matches: [],
  totalFarms: 0,
  loading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_PRODUCERS:
      return {
        ...state,
        producers: [...state.producers, ...action.payload],
      };
    case UPDATE_ONE_PRODUCER:
      const indexToUpdate = state.producers.findIndex(
        (producer) => producer.id_hash === action.payload.id_hash
      );
      const updatedProducers = [...state.producers];
      updatedProducers.splice(indexToUpdate, 1, action.payload);
      return {
        ...state,
        producers: updatedProducers,
      };
    case 'SAVE_ONE_PRODUCER':
      return {
        ...state,
        producers: [action.payload],
      };
    case SAVE_MATCHES:
      return {
        ...state,
        matches: [...action.payload],
      };
    case 'LOADING_PRODUCERS':
      return {
        ...state,
        loading: action.payload,
      };
    case 'PAGING_PRODUCERS':
      return {
        ...state,
        paginationOptions: { ...state.paginationOptions, ...action.payload },
      };
    case 'SET_NEXT_PAGE':
      return {
        ...state,
        paginationOptions: { ...state.paginationOptions, next: action.payload },
      };
    case 'RESET_PRODUCERS':
      return {
        ...state,
        producers: [],
      };
    case 'RESET_PAGINATION':
      return {
        ...state,
        paginationOptions: {
          page: 1,
          numPerPage: 10,
          next: null,
        },
      };
    case 'SAVE_TOTAL_FARMS':
      return {
        ...state,
        totalFarms: action.payload,
      };
    default:
      return state;
  }
}
