import React from 'react'

import './Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container d-flex gap-3 h-100 align-items-center">
        <span>©2022 ucrop.it</span>
        <span>Políticas de privacidad</span>
        <span>Términos y condiciones</span>
      </div>
    </footer>
  )
}

export default Footer
