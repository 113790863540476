import { axiosCircuitExporter } from "../config/axios";
import {
  SAVE_TOKEN,
  START_LOGIN,
  LOGIN_SUCESSFULL,
  START_LOGOUT,
  RESET_PRODUCERS,
  RESET_PAGINATION,
} from "../types";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";

export function loginAction(data) {
  const { isApp = false, authToken, ...restOfData } = data;

  return async (dispatch) => {
    try {
      dispatch(login());

      const response = await axiosCircuitExporter.post(
        "/auth/login",
        restOfData
      );
      sessionStorage.setItem(
        "token",
        JSON.stringify(response.data.access_token)
      );

      sessionStorage.setItem("isApp", isApp);

      sessionStorage.setItem("authToken", authToken);

      const tokenString = sessionStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      const payload = {
        token: userToken,
        user: jwt_decode(userToken),
        authToken,
        isApp,
      };

      window.location.replace("/");
      dispatch(loginSucessfull(payload));
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Usuario invalido",
        text: "Usuario o contraseña invalida",
        confirmButtonColor: "#96C24D",
      });
    }
  };
}

const login = () => ({
  type: START_LOGIN,
  payload: true,
});

const loginSucessfull = (token) => ({
  type: LOGIN_SUCESSFULL,
  payload: token,
});

export function isLoginAction() {
  return async (dispatch) => {
    const tokenString = sessionStorage.getItem("token");
    const isAppStorage = sessionStorage.getItem("isApp");
    const authToken = sessionStorage.getItem("authToken");
    const userToken = JSON.parse(tokenString);
    if (userToken) {
      dispatch(
        saveToken({
          token: userToken,
          user: jwt_decode(userToken),
          authToken: authToken,
          isApp: Boolean(isAppStorage),
        })
      );
    }
  };
}

export function logoutAction() {
  return async (dispatch) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Desea finalizar la sesión",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#96C24D",
      cancelButtonColor: "#ababab",
      confirmButtonText: "Si, cerrar sesión!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("isApp");
        sessionStorage.removeItem("authToken");
        window.location.replace("/");
        dispatch(logout());
        dispatch(resetProducers());
        dispatch(resetPagination());
      }
    });
  };
}

const logout = () => ({
  type: START_LOGOUT,
  payload: true,
});

const saveToken = (token) => ({
  type: SAVE_TOKEN,
  payload: token,
});

const resetProducers = () => ({
  type: RESET_PRODUCERS,
});

const resetPagination = () => ({
  type: RESET_PAGINATION,
});
