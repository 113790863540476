import React from "react";
import logoUcropit from "../images/logoUcropit.svg";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../actions/loginActions";

const Header = () => {
  const dispatch = useDispatch();

  const userStorage = useSelector((state) => {
    return state.token;
  });

  const token = userStorage.token;
  const user = userStorage.user;
  const isApp = userStorage.isApp;

  const submitLogout = () => {
    dispatch(logoutAction());
  };

  if (isApp) return null;

  return token ? (
    <header className="mb-3 border-bottom ">
      <div className="container h-100">
        <div
          className="d-grid gap-3 align-items-center h-100"
          style={{ gridTemplateColumns: "1fr 2fr" }}
        >
          <img src={logoUcropit} alt="logo-ucropit" width="120" />
          <div className="d-flex justify-content-end align-items-center h-50">
            <h4 className="nameExporter m-0">{user?.name}</h4>
            <div
              style={{
                width: 1,
                background: "#EBEBEB",
                height: "100%",
                margin: "0 1rem",
              }}
            ></div>
            <span className="logout" onClick={submitLogout}>
              Logout
            </span>
          </div>
        </div>
      </div>
    </header>
  ) : (
    <header className="mb-3 border-bottom ">
      <div className="container h-100">
        <div
          className="d-grid gap-3 align-items-center h-100"
          style={{ gridTemplateColumns: "1fr 2fr" }}
        >
          <img src={logoUcropit} alt="logo-ucropit" width="120" />
        </div>
      </div>
    </header>
  );
};

export default Header;
