import React from "react";
import editIcon from "../../../Assets/Edit.svg";
import bookIcon from "../../../Assets/Book.svg";
import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Tooltip from "@mui/material/Tooltip";
import { useQuery as useParams } from "../../../hooks/useQuery";

const ListProducts = ({ products }) => {
  const query = useParams();
  const role = query.get("role") ? query.get("role") : "";

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        pt: 10,
        px: 7,
      }}
    >
      <Typography component="h1" variant="h6" sx={{ fontSize: 24, fontWeight: 700, mb: 1 }}>
        Trazabilidad
      </Typography>
      <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 400, mb: 6 }}>
        {products
          ? "Edita las características y los logros sostenibles de la historia de productos."
          : "Agrega la historia de tus productos para resaltar las características y los logros sostenibles desde su orígen hasta su transporte, procesamiento y fabricación."}
      </Typography>
      <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 700, color: "#687083", mb: 1 }}>
        {products ? "Listado de historia de productos" : "Aún no tienes historia de productos."}
      </Typography>
      {products
        ? products.map((product, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                width: "100%",
                background: "#fafafa",
                borderRadius: "8px",
                border: "1px solid #ebebeb",
                fontSize: 14,
                alignItems: "stretch,",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <Typography component="h1" variant="h6" sx={{ fontSize: 16, fontWeight: 700 }}>
                {product.name}
              </Typography>
              <Box>
                {/* {role === "QR_ADMIN" ? (
                  <Link to={`/qr/${product.id}/stages`}>
                    <Tooltip title="Editar Marca" sx={{ mr: 3, py: 0 }}>
                      <Box component="img" src={editIcon} alt="edit-icon" width={24} />
                    </Tooltip>
                  </Link>
                ) : null} */}
                <Tooltip title="Listar QR" sx={{ py: 0 }}>
                  <Link to={`/qr/${product.id}?role=${role}`}>
                    <Box component="img" src={bookIcon} alt="book-icon" width={24} />
                  </Link>
                </Tooltip>
              </Box>
            </Box>
          ))
        : null}
    </Box>
  );
};

export default ListProducts;
